import React, {useCallback, useEffect} from 'react';
import DefaultLayout from './../layouts/DefaultLayout/DefaultLayout';
import './UsersFormPage.scss';
import {Container} from "react-bootstrap";
import usersAPI from "../../api/usersAPI";
import useAsyncData from "../../hooks/useAsyncData";
import {useParams, useHistory} from 'react-router-dom';
import AsyncContent from "../../components/AsyncContent/AsyncContent";
import UserCompleteForm from "../../components/UserCompleteForm/UserCompleteForm";
import route from "../../helpers/route";
import {useSelector} from "react-redux";
import {getPlatformRoles, getPlatforms} from "../../store/actions/common";
import {connect} from "react-redux";

const UsersFormPage = ({getPlatforms, getPlatformRoles}) => {
  const params = useParams();
  const history = useHistory();

  const [loadUser, user, loading, error] = useAsyncData(async ({setData, setError}, id) => {
    try{
      const response = await usersAPI.show(id);
      setData(response.data.data);
    }catch(e){
      setData(null);
      //setError(e.toString());
      setError('Error al cargar usuario');
    }
  });

  const [onSubmitHandler, _, formLoading, formError] = useAsyncData(async ({setError}, values) => {
    try{
      await usersAPI.update(user.id, values);
      history.push(route('users'));
    }catch(e){
      if(e.response?.status === 422){
        setError('Algun campo es invalido');
      }else{
        setError('Error');
      }
    }
  }, [user]);

  useEffect(() => {
    loadUser(params.id);
  }, [params.id]);

  const {platforms, platformRoles} = useSelector(state => state.common.extra);

  const [loadEntities, __, entitiesLoading, entitiesError] = useAsyncData(async ({setError, setLoading}) => {
    return Promise.all([
      getPlatforms(),
      getPlatformRoles(),
    ]).catch(err => setError('Error al cargar'));
  });

  useEffect(() => {
    if(platforms === null || platformRoles === null){
      loadEntities();
    }
  }, []);

  return (
    <DefaultLayout title="Editando usuario">
      <div className="UsersFormPage">
        <div className="main-container">
          <Container fluid="xl" className="container--xs-full">
            <div className="page-card">
              <h2 className="page-card__title">
                Editando usuario
              </h2>
              <div className="page-card__content">
                <AsyncContent
                  hasError={!!error || !!entitiesError}
                  isLoading={loading || entitiesLoading}
                  errorMessage={error | entitiesError}
                  onRetryHandler={error ? loadUser.bind(null, params.id) : loadEntities}
                >
                  {user && (
                    <UserCompleteForm
                      onSubmit={onSubmitHandler}
                      initialValues={{
                        ...user,
                        user_roles: user.roles.map((data) => ({
                          platform_id: data.platform_id,
                          role_id: data.id,
                        }))
                      }}
                      loading={formLoading}
                      serverError={formError}
                      platforms={platforms}
                      platformRoles={platformRoles}
                    />
                  )}
                </AsyncContent>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </DefaultLayout>
  );
}

const mapDispatchToProps = {
  getPlatforms,
  getPlatformRoles,
}

UsersFormPage.propTypes = {

};

export default connect(null, mapDispatchToProps)(UsersFormPage);