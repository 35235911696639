import OnboardingHeader from "../OnboardingHeader/OnboardingHeader";
import React, {useCallback, useState} from "react";
import './OnboardingStepBusiness.scss';
import BusinessDataForm from "../BusinessDataForm/BusinessDataForm";
import useIsMobile from "../../hooks/useIsMobile";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import onboardingAPI from "../../api/onboardingAPI";
import useAsyncData from "../../hooks/useAsyncData";

const OnboardingStepBusiness = ({
                                  onDone,
                                  onboardingData,
                                  businessActivities,
                                  businessTypes,
                                  provinces,
                                  fiscalConditions,
                                }) => {

  const [step, setStep] = useState(1);
  const isMobile = useIsMobile();

  const [submitHandler, _, loading, error] = useAsyncData(async ({setLoading, setError}, values) => {
    try{
      if(onboardingData.business){
        const response = await onboardingAPI.patchBusiness(onboardingData.business.id, values);
        onDone && onDone(response.data.data);
      }else{
        const response = await onboardingAPI.saveBusiness(values);
        onDone && onDone(response.data.data);
      }
    }catch(e){
      if(e.response.status === 422){
        setError('Los datos son invalidos');
      }else{
        setError('Ocurrio un error');
      }
    }
  }, [onDone]);

  return (
    <>
      {step === 1 ? (
        <OnboardingHeader title="Datos del comercio">
          Toda la informacion que nos brindes es privada, no sera compartida con otra entidad.
        </OnboardingHeader>
      ) : (
        <OnboardingHeader title="Más datos del comercio">
          Solo unos datos más de tu comercio, ya queda poco.
        </OnboardingHeader>
      )}

      <BusinessDataForm
        businessActivities={businessActivities}
        businessTypes={businessTypes}
        provinces={provinces}
        fiscalConditions={fiscalConditions}
        onStepChange={setStep}
        onSubmit={submitHandler}
        nextStepLabel="Continuar al próximo paso"
        submitLabel="Continuar al próximo paso"
        singleStep={!isMobile}
        initialValues={onboardingData.business || {}}
        loading={loading}
        serverError={error}
        existingBusinessId={onboardingData?.business?.id || null}
      />
    </>
  )
}

OnboardingStepBusiness.propTypes = {
  onDone: PropTypes.func.isRequired,
  onboardingData: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  businessActivities: state.common.extra.businessActivities,
  businessTypes: state.common.extra.businessTypes,
  provinces: state.common.extra.provinces,
  fiscalConditions: state.common.extra.fiscalConditions,
})

export default connect(mapStateToProps)(OnboardingStepBusiness);