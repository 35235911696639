import './AlertContainer.scss';
import React, {useEffect, useReducer, useRef} from 'react';
import {Alert} from "react-bootstrap";
import useAlerts from "../../hooks/useAlerts";

const AlertContainer = () => {
  const alerts = useAlerts();

  return (
    <div className="AlertContainer">
      {alerts.items.map(alert => (
        <Alert
          key={alert.id}
          variant={alert.type}
          onClose={() => alerts.dismiss(alert.id)}
          dismissible
        >
          <AlertAutoDismiss
            onDismiss={() => alerts.dismiss(alert.id)}
            delay={10}
          />
          <Alert.Heading>
            {alert.title}
          </Alert.Heading>
          {alert.message && (
            <p>
              {alert.message}
            </p>
          )}
        </Alert>
      ))}
    </div>
  )
}

const AlertAutoDismiss = ({delay = 5, onDismiss}) => {
  const timeout = useRef(null);

  useEffect(() => {
    if(onDismiss){
      timeout.current = setTimeout(() => {
        onDismiss();
      }, delay * 1000)

      return () => {
        clearTimeout(timeout.current);
      }
    }
  }, [delay, onDismiss]);

  return null;
}

AlertContainer.propTypes = {

};

export default AlertContainer;