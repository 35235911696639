import React from "react"
import PropTypes from "prop-types"
import ReactCodeInput from "react-code-input";

const CodeCustom = ({ title, id, name, value, error, onChange, fields, ...rest }) => {
  const inputStyle = {
    fontFamily: 'monospace',
    MozAppearance: 'textfield',
    boxShadow: 'none',
    margin: '4px',
    paddingLeft: '8px',
    paddingRight: 0,
    height: '65px',
    fontSize: '32px',
    width: '20%',
    color: '#1A95D3',
    borderRadius: 0,
    border: 'none',
    textAlign: 'center',
    borderBottom: '2px solid grey',
  };

  return (
    <>
      <label htmlFor={id} className="label-inputs">{title}</label>
      <div>
        <ReactCodeInput
          name={name}
          value={value}
          onChange={onChange}
          fields={fields}
          inputMode='number'
          inputStyle={inputStyle}
          {...rest}
        />
      </div>
    </>
  )
}

CodeCustom.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
}

export default CodeCustom
