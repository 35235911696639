import React, {useEffect, useState} from "react";
import {useFormik, FormikProvider, FieldArray} from "formik";
import * as Yup from "yup";
import {Button, Form} from "react-bootstrap";
import './UserColleaguesForm.scss';
import SelectCustom from "../forms/SelectCustom";
import InputCustom from "../forms/InputCustom";
import PropTypes from "prop-types";
import ButtonCustom from "../forms/ButtonCustom";
import PhoneInputCustom from "../forms/PhoneInputCustom";

const makeEmptyColleague = () => ({
  id: -new Date(),
  first_name: '',
  last_name: '',
  business_name: '',
  cellphone: '',
  email: '',
  phone: null,
});

const UserColleaguesForm = ({
                              onSubmit,
                              initialValues = {},
                              submitLabel,
                              loading = false,
                              readonly = false,
                              serverError,
                              maxColleagues = 2,
                              minColleagues = 0
}) => {
  const formik = useFormik({
    initialValues: {
      colleagues: minColleagues > 0 ? [makeEmptyColleague()] : [],
      ...initialValues,
    },
    onSubmit: values => onSubmit && onSubmit(values),
    validationSchema: Yup.object().shape({
      colleagues: Yup.array().of(
        Yup.object().shape({
          first_name: Yup.string().required('Obligatorio'),
          last_name: Yup.string().required('Obligatorio'),
          business_name: Yup.string().required('Obligatorio'),
          cellphone: Yup.string()
            .required('Obligatorio')
            .matches(
              "^0(\\d{2,4})\\ 15(\\d{4,8})$",
              "Debe ingresar codigo de area y numero de celular"
            ),
          email: Yup.string().email('Invalido').required('Obligatorio'),
          phone: Yup.string()
            .nullable()
            .matches(
              "^0(\\d{2,4})\\ (\\d{5,10})$",
              "Debe ingresar codigo de area y numero de telefono"
            ),
        }),
      ).max(maxColleagues, `Maximo ${maxColleagues} referidos`)
       .min(minColleagues, `Minimo ${minColleagues} referidos`),
    }),
  });

  /* Controlan la existencia del select "Otro referido" al final del listado de referidos, para agregar otro */
  const [newColleagueStatus, setNewColleagueStatus] = useState(false);

  useEffect(() => {
    if(formik.values.colleagues.length >= maxColleagues){
      setNewColleagueStatus(null);
    }else{
      setNewColleagueStatus(false);
    }
  }, [formik.values.colleagues]);
  /* Controlan la existencia del select "Otro referido" al final del listado de referidos, para agregar otro */

  return (
    <Form className="UserColleaguesForm" onSubmit={formik.handleSubmit}>
      <FormikProvider value={formik}>
        <FieldArray name="colleagues">
          {({remove, push}) => (
            <>
              {formik.values.colleagues.map((colleague, index) => (
                <div className="subform" key={colleague.id}>
                  <Form.Group>
                    <SelectCustom
                      title={index ? 'Otro referido' : 'Referido'}
                      onChange={val => remove(index)}
                      disabled={loading || readonly}
                      value="1"
                    >
                      <option value="">No</option>
                      <option value="1">Si</option>
                    </SelectCustom>
                  </Form.Group>

                  <Form.Group>
                    <InputCustom
                      title="Nombres"
                      autoComplete="off"
                      placeholder="Ingresa los nombres completos"
                      onChange={formik.handleChange}
                      name={`colleagues[${index}].first_name`}
                      value={formik.values.colleagues[index].first_name}
                      id={`colleagues[${index}].first_name`}
                      type="text"
                      disabled={loading || readonly}
                      error={formik.touched?.colleagues?.[index]?.first_name && formik.errors?.colleagues?.[index]?.first_name}
                    />
                  </Form.Group>

                  <Form.Group>
                    <InputCustom
                      title="Apellido"
                      autoComplete="off"
                      placeholder="Ingresa los apellidos completos"
                      onChange={formik.handleChange}
                      name={`colleagues[${index}].last_name`}
                      value={formik.values.colleagues[index].last_name}
                      id={`colleagues[${index}].last_name`}
                      type="text"
                      disabled={loading || readonly}
                      error={formik.touched?.colleagues?.[index]?.last_name && formik.errors?.colleagues?.[index]?.last_name}
                    />
                  </Form.Group>

                  <Form.Group>
                    <InputCustom
                      title="Nombre del comercio"
                      autoComplete="off"
                      placeholder="Ingresa el nombre del comercio"
                      onChange={formik.handleChange}
                      name={`colleagues[${index}].business_name`}
                      value={formik.values.colleagues[index].business_name}
                      id={`colleagues[${index}].business_name`}
                      type="text"
                      disabled={loading || readonly}
                      error={formik.touched?.colleagues?.[index]?.business_name && formik.errors?.colleagues?.[index]?.business_name}
                    />
                  </Form.Group>

                  <Form.Group>
                    <PhoneInputCustom
                      title="Celular"
                      autoComplete="off"
                      maxlength_prefix="4"
                      maxlength_number="8"
                      start_number="15"
                      start_prefix="0"
                      autocomplete="off"
                      placeholder_number="00000000"
                      placeholder_prefix="11"
                      onValueChange={formik.setFieldValue.bind(null, `colleagues[${index}].cellphone`)}
                      name={`colleagues[${index}].cellphone`}
                      value={formik.values.colleagues[index].cellphone}
                      id={`colleagues_${index}_cellphone`}
                      disabled={loading || readonly}
                      error={formik.touched?.colleagues?.[index]?.cellphone && formik.errors?.colleagues?.[index]?.cellphone}
                    />
                  </Form.Group>

                  <Form.Group>
                    <InputCustom
                      title="Mail"
                      autoComplete="off"
                      placeholder="Ingresa su email"
                      onChange={formik.handleChange}
                      name={`colleagues[${index}].email`}
                      value={formik.values.colleagues[index].email}
                      id={`colleagues[${index}].email`}
                      type="email"
                      disabled={loading || readonly}
                      error={formik.touched?.colleagues?.[index]?.email && formik.errors?.colleagues?.[index]?.email}
                    />
                  </Form.Group>

                  <Form.Group>
                    <PhoneInputCustom
                      title="Telefono fijo"
                      onValueChange={(val) => (formik.setFieldValue(`colleagues[${index}].phone`, val))}
                      placeholder=""
                      name={`colleagues[${index}].phone`}
                      value={formik.values.colleagues[index].phone || ''}
                      id={`colleagues[${index}].phone`}
                      placeholder_prefix="11"
                      maxlength_number={10}
                      maxlength_prefix={3}
                      start_prefix="0"
                      start_number=""
                      disabled={loading || readonly}
                      error={formik.touched?.colleagues?.[index]?.phone && formik.errors?.colleagues?.[index]?.phone}
                    />
                  </Form.Group>
                  <hr/>
                </div>
              ))}

              {newColleagueStatus !== null && (
                <Form.Group>
                  <SelectCustom
                    title={formik.values.colleagues.length ? 'Otro referido' : 'Referido'}
                    onChange={val => val && push(makeEmptyColleague()) && setNewColleagueStatus(false)}
                    value={newColleagueStatus}
                    disabled={loading || readonly}
                  >
                    <option value="">No</option>
                    <option value="1">Si</option>
                  </SelectCustom>
                </Form.Group>
              )}
            </>
          )}
        </FieldArray>

        {serverError && (<p className="server-error">{serverError}</p>)}

        {!readonly && (
          <div>
            <ButtonCustom
              type="submit"
              className="UserColleaguesForm__submit"
              size="lg"
              block={true}
              variant="primary"
              loading={loading}
            >
              {submitLabel || 'Guardar'}
            </ButtonCustom>
          </div>
        )}
      </FormikProvider>
    </Form>
  );
}

UserColleaguesForm.propTypes = {
  onSubmit: PropTypes.func,
  initialValues: PropTypes.shape({

  }),
  submitLabel: PropTypes.string,
  loading: PropTypes.bool,
  readonly: PropTypes.bool,
  maxColleagues: PropTypes.number,
  minColleagues: PropTypes.number,
  serverError: PropTypes.string,
}

export default UserColleaguesForm;