import React, {useState, useEffect} from 'react';
import {Modal, Button, FormGroup, Form} from 'react-bootstrap';
import './ChangePasswordModal.scss';
import InputCustom from "../forms/InputCustom";
import {useFormik} from "formik";
import * as Yup from "yup";
import accountAPI from "../../api/accountAPI";

let closeModalTimeout;

const ChangePasswordModal = ({show, handleClose}) => {
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState(null);

  useEffect(() => {
    return () => clearTimeout(closeModalTimeout);
  })

  const formik = useFormik({
    initialValues: {
      current_password: '',
      new_password: '',
      //new_password_confirmation: '',
    },
    onSubmit: async (values) => {
      setLoading(true);
      setFeedback(null);

      try{
        await accountAPI.changePassword({...values, new_password_confirmation: values.new_password});
        setFeedback({success: true, message: 'Contraseña actualizada correctamente'});
        handleClose();
        closeModalTimeout = setTimeout(handleClose, 2000);
      }catch(e){
        if (e.response.status === 422){
          if(e.response.data.errors.current_password){
            setFeedback({message: 'Su contraseña anterior no coincide', success: false});
          }else{
            setFeedback({message: 'Algun campo es invalido', success: false});
          }
        }else{
          setFeedback({message: 'Ocurrio un error al cambiar la contraseña.', success: false});
        }

        setLoading(false);
      }
    },
    validationSchema: Yup.object().shape({
      current_password: Yup.string()
        .required('Obligatorio'),
      new_password: Yup.string()
        .required('Obligatorio'),
      /*new_password_confirmation: Yup.string()
        .oneOf([Yup.ref('new_password'), null], 'Las contraseñas no coinciden')
        .required('Obligatorio'),*/
    }),
  });

  return (
    <Modal className="change-password ChangePasswordModal" show={show} onHide={!loading ? handleClose : () => {}}>
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton={!loading}>
          <Modal.Title>Cambiar contraseña</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <FormGroup>
            <InputCustom
              value={formik.values.current_password}
              onChange={formik.handleChange}
              name="current_password"
              type="password"
              title="Contraseña actual"
              disabled={loading}
              error={formik.touched.current_password && formik.errors.current_password}
            />
          </FormGroup>
          {/*<hr/>*/}
          <FormGroup>
            <InputCustom
              value={formik.values.new_password}
              onChange={formik.handleChange}
              name="new_password"
              type="password"
              title="Nueva contraseña"
              disabled={loading}
              error={formik.touched.new_password && formik.errors.new_password}
            />
          </FormGroup>
          {/*<FormGroup>
            <InputCustom
              value={formik.values.new_password_confirmation}
              onChange={formik.handleChange}
              name="new_password_confirmation"
              type="password"
              title="Repetir nueva contraseña"
              disabled={loading}
              error={formik.touched.new_password && formik.errors.new_password}
            />
          </FormGroup>*/}

          {feedback && (
            <div className={`change-password__feedback ${feedback.success ? 'change-password__feedback--success' : 'change-password__feedback--error'}`}>
              {feedback.message}
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="outline-primary"
            onClick={handleClose}
            disabled={loading}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            variant="primary"
            disabled={loading}
          >
            Guardar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

ChangePasswordModal.propTypes = {

}

export default ChangePasswordModal;