import {
  SET_GLOBAL_LOADER,
  SET_FISCAL_CONDITIONS,
  SET_BUSINESS_TYPES,
  SET_BUSINESS_ACTIVITIES,
  SET_SOCIAL_MEDIA_SERVICES,
  SET_PLATFORMS,
  SET_PLATFORM_ROLES,
  SET_PROVINCES,
  ADD_ALERT,
  CLEAR_ALERTS,
  DISMISS_ALERT,
} from "../types/common";

const initialState = {
  loaders: {
    global: false,
  },
  alerts: [],
  extra: {
    fiscalConditions: null,
    businessTypes: null,
    businessActivities: null,
    socialMediaServices: null,
    platforms: null,
    platformRoles: null,
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_GLOBAL_LOADER: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          global: action.payload,
        },
      }
    }

    case SET_PLATFORMS: {
      return {
        ...state,
        extra: {
          ...state.extra,
          platforms: action.payload,
        },
      }
    }

    case SET_PLATFORM_ROLES: {
      return {
        ...state,
        extra: {
          ...state.extra,
          platformRoles: action.payload,
        },
      }
    }

    case SET_FISCAL_CONDITIONS: {
      return {
        ...state,
        extra: {
          ...state.extra,
          fiscalConditions: action.payload,
        },
      }
    }

    case SET_BUSINESS_ACTIVITIES: {
      return {
        ...state,
        extra: {
          ...state.extra,
          businessActivities: action.payload,
        },
      }
    }

    case SET_BUSINESS_TYPES: {
      return {
        ...state,
        extra: {
          ...state.extra,
          businessTypes: action.payload,
        },
      }
    }

    case SET_PROVINCES: {
      return {
        ...state,
        extra: {
          ...state.extra,
          provinces: action.payload,
        },
      }
    }

    case SET_SOCIAL_MEDIA_SERVICES: {
      return {
        ...state,
        extra: {
          ...state.extra,
          socialMediaServices: action.payload,
        },
      }
    }

    case CLEAR_ALERTS: {
      return {
        ...state,
        alerts: [],
      }
    }

    case ADD_ALERT: {
      return {
        ...state,
        alerts: [...state.alerts, {...action.payload, id: +new Date}],
      }
    }

    case DISMISS_ALERT: {
      return {
        ...state,
        alerts: state.alerts.filter(al => al.id !== action.payload.id),
      }
    }

    default: {
      return state
    }
  }
}
