import React, {useState} from 'react';
import EmptyLayout from "../layouts/EmptyLayout/EmptyLayout";
import {Button, Form} from "react-bootstrap";
import {useParams, useLocation, useHistory} from "react-router-dom";
import './RecoverPasswordPage.scss';
import InputCustom from "../../components/forms/InputCustom";
import {recoverPassword, login} from "../../store/actions/auth";
import {connect} from "react-redux";
import {useFormik} from "formik";
import * as QueryString from "query-string";
import * as Yup from "yup";
import route from "../../helpers/route";

const RecoverPasswordPage = ({recoverPassword, login}) => {
  const { search } = useLocation();
  const { token } = useParams();
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const history = useHistory();

  const query = QueryString.parse(search);
  const formik = useFormik({
    initialValues: {
      password: '',
      //password_confirmation: '',
    },
    onSubmit: async (values) => {
      setLoading(true);
      setFeedback(null);

      try{
        await recoverPassword({
          password: values.password,
          password_confirmation: values.password,
          token,
          email: query.email
        });

        try{
          await login(query.email, values.password, true);
        }catch(e){
          //TODO: Ver por que history.replace solo cambia la url sin movernos
          window.location.href = route('login');
          //history.replace(route('login'))
          return;
        }
      }catch(e){
        if (e.response.status === 422){
          if(e.response.data?.errors?.token){
            setFeedback({message: 'El enlace expiró', success: false});
          }else{
            setFeedback({message: 'Algun campo es invalido', success: false});
          }
        }else if(e.response.status === 400){
          setFeedback({message: 'Este enlace de contraseña ha expirado, debe solicitar nuevamente el cambio de contraseña.', success: false});
        }else{
          setFeedback({message: 'Ocurrio un error al cambiar la contraseña.', success: false});
        }
      }

      setLoading(false);
    },
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .min(8, 'Minimo 8 caracteres')
        .required('Obligatorio'),
      /*password_confirmation: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden')
        .required('Obligatorio'),*/
    }),
  });

  return (
    <EmptyLayout>
      <div className="RecoverPasswordPage auth-content__inner">
        <div className="recover-password">
          <h3 className="recover-password__title auth-title">
            {query.new ? 'Crear contraseña' : 'Recuperar contraseña'}
          </h3>
          <Form className="recover-password__form" onSubmit={formik.handleSubmit}>
            <Form.Group>
              <InputCustom
                title={query.new ? 'Contraseña' : 'Nueva contraseña'}
                autoComplete="off"
                name="password"
                onChange={formik.handleChange}
                value={formik.values.password}
                id="password"
                type="password"
                disabled={loading}
                error={formik.touched.password && formik.errors.password}
              />
            </Form.Group>

            {/*<Form.Group>
              <InputCustom
                title="Repetir nueva contraseña"
                autoComplete="off"
                name="password_confirmation"
                onChange={formik.handleChange}
                value={formik.values.password_confirmation}
                id="password_confirmation"
                type="password"
                disabled={loading}
                error={formik.touched.password_confirmation && formik.errors.password_confirmation}
              />
            </Form.Group>*/}

            <div>
              {feedback && (
                <div className={`auth__feedback ${feedback.success ? 'auth__feedback--success' : 'auth__feedback--error'}`}>
                  {feedback.message}
                </div>
              )}

              <Button
                type="submit"
                className="auth-btn recover-password__btn"
                size="lg"
                block={true}
                variant="primary"
                disabled={loading}
              >
                {search.new ? 'Generar contraseña' : 'Cambiar contraseña'}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </EmptyLayout>
  );
}

RecoverPasswordPage.propTypes = {

};

const mapDispatchToProps = {
  recoverPassword,
  login,
};

export default connect(null, mapDispatchToProps)(RecoverPasswordPage);