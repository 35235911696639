import axios from "axios"
import config from "../config/index"
import { store } from "../store"
import authAPI from "./authAPI"
import { logout, } from "../store/actions/auth"
import {AUTH, LOGOUT} from "../store/types/auth"

class HttpService {
  static instance;

  constructor() {
    this.cancelToken = this.constructor.CancelToken.source();

    this.axios = axios.create({
      baseURL: config.apiURL,
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      cancelToken: this.cancelToken.token,
    })
  }

  static errorIsCancel(e){
    return axios.isCancel(e);
  }

  static getInstance() {
    if (!HttpService.instance){
      HttpService.instance = new HttpService()
    }

    return HttpService.instance
  }

  cancel = (message = "Cancel request") => {
    this.cancelToken.cancel(message)
  }

  call = async (method, url, body = {}) => {
    try {
      return await this.axios.request({
        method, url, ...body,
      })
    } catch (e) {
      if (e.response?.status === 500){
        console.error("Server Error:", e.response.data.message);
      }

      throw e
    }
  }

  authCall = async (method, url, body = {}) => {
    const state = store.getState()
    const accessToken = state.auth.tokens?.access_token

    body.headers = {
      Authorization: `Bearer ${accessToken}`,
    }

    try {
      return await this.call(method, url, body)
    } catch (e) {
      if (e.response?.status === 401) { // Unable to access to this resource
        //store.dispatch({ type: LOGOUT });
        const state = store.getState();
        const refreshToken = state.auth.tokens?.refresh_token;
        const responseTokenRenew = await this.renewToken(refreshToken);

        if (!refreshToken || responseTokenRenew.status === 401 || responseTokenRenew.status === 400) {
          logout();
          throw e;
        }

        //TODO: Test para ver si el renew token falla por que se da una condicion de carrera entre actualizar estado con nuevo token y que salga la segunda request
        await new Promise((resolve) => setTimeout(resolve, 2500));

        return await this.authCall(method, url, body)
      } else if (e.response?.status === 500) { // Server error
        console.error("Server Error:", e.response);
      }

      throw e;
    }
  }

  renewToken = (refreshToken) => {
    const state = store.getState()

    return authAPI.renewToken({
        grant_type: "refresh_token",
        client_id: config.clientId,
        client_secret: config.clientSecret,
        scope: "*",
        refresh_token: refreshToken,
      })
      .then(response => {
        store.dispatch({
          type: AUTH,
          payload: {
            tokens: response.data,
            remember: true,
            isUserLoading: state.auth.user === null,
          },
        });

        return response;
      })
      .catch(e => e.response);
  }
}

HttpService.CancelToken = axios.CancelToken;

export default HttpService
