import React from "react"
import PropTypes from "prop-types"
import {Button} from "react-bootstrap";
import Spinner from "../Spinner/Spinner";
import './ButtonCustom.scss';

const ButtonCustom = ({loading = false, className = '', disabled, children, ...rest }) => {
  return (
    <Button
      className={`ButtonCustom ${className}`}
      disabled={loading || disabled}
      {...rest}
    >
      <>
        {loading && (
          <div className="ButtonCustom__spinner_container">
            <Spinner size={24}/>
          </div>
        )}
        {children}
      </>
    </Button>
  )
}

ButtonCustom.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
}

export default ButtonCustom
