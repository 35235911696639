import React from "react";
import './OnboardingHeader.scss';

const OnboardingHeader = ({title, children}) => {
  return (
    <div className="OnboardingHeader">
      <h4 className="OnboardingHeader__title">
        {title}
      </h4>
      <h6 className="OnboardingHeader__description">
        {children}
      </h6>
    </div>
  );
}

export default OnboardingHeader;