import React from 'react';
import DefaultLayout from './../layouts/DefaultLayout/DefaultLayout';
import './HomePage.scss';
import {Col, Container} from "react-bootstrap";

const HomePage = (props) => {
  return (
    <DefaultLayout title="Inicio">
      <div className="HomePage">
        <Container fluid="md" className="container--xs-full main-container">
          <div className="page-card">
            <h2 className="page-card__title">
              Bienvenido
            </h2>
            <div className="page-card__content">
              <p>Bienvenido a decreditos</p>
            </div>
          </div>
        </Container>
      </div>
    </DefaultLayout>
  );
}

HomePage.propTypes = {

};

export default HomePage;