import {useEffect, useRef, useDebugValue, useCallback} from 'react';

export class CancelledError extends Error {
  constructor(message) {
    super(message);
    this.name = "CancelledError";
    this.isCancelled = true;
  }
}

/**
 * Returns a function that can be used to wrap promises, wrapped promises will be automatically rejected when the component
 * unmounts, when promises are rejected they will receive a CancelledError as argument, this allows you to check whether it
 * was a "Cancellation" error, or an ordinary error, this is important since when the rejection is because of cancellation
 * you shouldn't call any setState functions, since the component is unmounting.
 *
 * Example:
 *
 * const asyncWrapper = useAsyncWrap();
 *
 * asyncWrapper(fetch('/test'))
 *  .then(res => {console.log(res)})
 *  .catch(err => {
 *    if(!err.isCancelled){
 *      setStateFunc('Oh noes!');
 *    }
 *
 *    //or
 *
 *    if(!err instanceof CancelledError){
 *      setStateFunc('Oh noes!');
 *    }
 *  })
 *
 *
 *  Example using async.
 *
 *  const asyncWrapper = useAsyncWrap();
 *
 * try{
 *    const response = await asyncWrapper(fetch('/test'));
 *    console.log({response});
 * }catch(err){
 *   if(!err.isCancelled){
 *      if(err.response.status === 422){
 *          setStateFunc('The request failed, and you made a mistake mr. customer!');
 *      }else{
 *          setStateFunc('The request failed, but it was nothing personal mr. customer!');
 *      }
 *   }
 * }
 *
 *
 * Note: this hook rejects promises, but if the promise is from a request, you may want to also cancel the request.
 * Sometimes it's not mandatory but in some cases (file upload or similar) you should be cancelling the requests to avoid
 * unncesesary bandwidth usage, and its outside the scope of this hook, if your promise requires additional cleanup you can
 * perform the aforementioned cleanups additional to using this hook (Ex: using axios cancel function or cancel token)
 *
 * @returns {function(*): Promise<unknown>}
 */
const useAsyncWrap = () => {
  const rejectFunctions = useRef([]);

  useDebugValue(rejectFunctions, rejectFunctions => rejectFunctions.current.length);

  useEffect(() => {
    return () => {
      rejectFunctions.current.forEach((rejectFunct) => {
        rejectFunct(new CancelledError('Cancelled promise'));
      });

      rejectFunctions.current = [];
    }
  }, []);

  return useCallback((promise) => {
    return new Promise((resolve, reject) => {
      rejectFunctions.current.push(reject);

      promise.then(resolve)
             .catch(reject)
             .then(() => {
               const index = rejectFunctions.current.indexOf(reject);

               if(index >= 0){
                 rejectFunctions.current.splice(index, 1);
               }
             })
    });
  }, []);
}

export default useAsyncWrap;
