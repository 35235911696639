import React from "react";
import PropTypes from 'prop-types';
import './Pagination.scss';
import AsyncContent from "../AsyncContent/AsyncContent";

const Pagination = ({pagination, children, errorWrapper}) => {
  return (
    <AsyncContent
      hasError={!!pagination.error}
      isLoading={pagination.loading}
      onRetryHandler={pagination.formik.submitForm}
    >
      {pagination.data && children(pagination.data.data)}
    </AsyncContent>
  )
}

Pagination.propTypes = {
  meta: PropTypes.shape({
    per_page: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]).isRequired,
    from: PropTypes.number,
    last_page: PropTypes.number.isRequired,
    current_page: PropTypes.number.isRequired,
    to: PropTypes.number,
    total: PropTypes.number.isRequired,
  }),
}

export default Pagination;