const invalidTLDs = [
  'con',
];

/**
 *
 * @param email
 * @returns {boolean}
 */
const hasValidTLD = (email) => {
  const tld = email.split('.').pop();

  return !invalidTLDs.includes(tld);
}

/**
 *
 * @param email
 * @returns {boolean}
 */
const hasWhitespace = (email) => {
  return email.indexOf(' ') >= 0;
}

/**
 *
 */
export default (email) => {
  if(typeof email !== "string"){
    return false;
  }

  /* Has Whitespaces */
  if(hasWhitespace(email)){
    return false;
  }
  /* Has Whitespaces */

  /* Has invalid TLD */
  if(!hasValidTLD(email)){
    return false;
  }
  /* Has invalid TLD */

  return true;
}