import OnboardingHeader from "../OnboardingHeader/OnboardingHeader";
import React, {useState} from "react";
import './OnboardingStepSocialMedia.scss';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import SocialMediaForm from "../SocialMediaForm/SocialMediaForm";
import onboardingAPI from "../../api/onboardingAPI";
import useAsyncData from "../../hooks/useAsyncData";

const OnboardingStepSocialMedia = ({
                                      onDone,
                                      onboardingData,
                                      socialMediaServices
                                    }) => {
  const [submitHandler, _, loading, error] = useAsyncData(async ({setLoading, setError}, values) => {
    try{
      await onboardingAPI.patchBusiness(onboardingData.business.id, {social_media_accounts: values.accounts});
      const response = await onboardingAPI.finish();

      onDone && onDone(response.data.data);
    }catch(e){
      if(e.response?.status === 422){
        setError('Algun campo es invalido');
      }else{
        setError('Ocurrio un error');
      }
    }
  }, [onDone]);

  return (
    <div className="OnboardingStepSocialMedia">
      <OnboardingHeader title="Redes sociales">
        Mientras más datos completes más rápido operarás con nosotros.
      </OnboardingHeader>
      <SocialMediaForm
        onSubmit={submitHandler}
        socialMediaServices={socialMediaServices}
        initialValues={{accounts: onboardingData.business.social_media_accounts}}
        submitLabel="Finalizar registro"
        loading={loading}
        serverError={error}
      />
    </div>
  )
}

OnboardingStepSocialMedia.propTypes = {
  onDone: PropTypes.func.isRequired,
  onboardingData: PropTypes.object.isRequired,
  socialMediaServices: PropTypes.array.isRequired,
}

export default OnboardingStepSocialMedia;