import HttpService from "./HttpService";

const httpService = HttpService.getInstance();

export default {
  show: id => httpService.authCall("GET", `/api/users/${id}`),
  update: (id, data) => httpService.authCall("PUT", `/api/users/${id}`, {data}),
  create: (data) => httpService.authCall("POST", `/api/users`, {data}),
  index: (params) => httpService.authCall('GET', '/api/users', {params}),
  resendPasswordEmail: id => httpService.authCall("POST", `/api/users/${id}/resend-password-email`, {data: {}})
}
