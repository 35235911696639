import React, {useCallback, useEffect, useMemo} from "react";
import {Button, Form} from "react-bootstrap";
import './VerifyEmailForm.scss';
import PropTypes from "prop-types";
import ButtonCustom from "../forms/ButtonCustom";
import authAPI from "../../api/authAPI";
import useAsyncData from "../../hooks/useAsyncData";
import Spinner from "../Spinner/Spinner";

const VerifyEmailForm = ({
                           onSubmit, 
                           initialValues = {}, 
                           submitLabel, 
                           loading = false, 
                           readonly = false,
                           serverError,
}) => {
  const onSubmitHandler = useCallback(e => {
    e.preventDefault();
    onSubmit && onSubmit();
  }, [onSubmit]);

  const [onResendHandler, resendFeedback, resendLoading, resendError] = useAsyncData(({setLoading, setError, setData}, e) => {
    e.preventDefault();
    return authAPI.resendEmail()
      .then((resp) => {
        setData({success: true, message: 'Hemos reenviado el correo de verificacion'})
      }).catch(e => {
        setData({success: false, message: 'Error al reenviar el correo'})
      });
  }, []);

  return (
    <Form className="VerifyEmailForm" onSubmit={onSubmitHandler}>
      <div className="VerifyEmailForm__main">
        <p>
          Revise su correo, deberia haber recibido un email con un enlace para validar su direccion de email.
        </p>
        {resendLoading ? (<Spinner size={75}/>) : (
          <>
            {resendFeedback && (
              <p>{resendFeedback.message}</p>
            )}
            { ! resendFeedback?.success && (
              <a className="text-center" href="#" onClick={onResendHandler}>
                No recibi el correo, reenviar
              </a>
            )}
          </>
        )}
      </div>

      {serverError && (<p className="server-error">{serverError}</p>)}

      {!readonly && (
        <div>
          <ButtonCustom
            type="submit"
            className="VerifyEmailForm__submit"
            size="lg"
            block={true}
            variant="primary"
            loading={loading || resendLoading}
          >
            {submitLabel || 'Guardar'}
          </ButtonCustom>
        </div>
      )}
    </Form>
  );
}

VerifyEmailForm.propTypes = {
  onSubmit: PropTypes.func,
  initialValues: PropTypes.shape({

  }),
  submitLabel: PropTypes.string,
  loading: PropTypes.bool,
  readonly: PropTypes.bool,
  serverError: PropTypes.string,
}

export default VerifyEmailForm;