import HttpService from "./HttpService";

const httpService = HttpService.getInstance();

export default {
  login: body => httpService.call("POST", "/oauth/token", body),
  renewToken: data => httpService.call("POST", "/oauth/token", {data}),
  //logout: tokenId => httpService.call("DELETE", "/oauth/token/" + tokenId),
  logout: () => httpService.authCall("POST", "/api/auth/logout"),

  register: (data) => httpService.call("POST", "/api/auth/register", {data}),
  forgotPassword: body => httpService.call("POST", "/api/auth/forgot-password", body),
  recoverPassword: body => httpService.call("POST", "/api/auth/reset-password", body),

  verifyEmail: (email, hash) => httpService.authCall("POST", `/api/auth/verify-email/${email}/${hash}`),
  resendEmail: () => httpService.authCall("POST", "/api/auth/resend-email"),
}
