import React, {useCallback, useEffect, useState} from "react";
import './PersonalDataMatiVerificationForm.scss';
import PropTypes from "prop-types";
import Spinner from "../Spinner/Spinner";
import {Button} from "react-bootstrap";
import ButtonCustom from "../forms/ButtonCustom";

const PersonalDataMatiVerificationForm = ({
                                        onSubmit,
                                        initialValues = {},
                                        nextStepLabel,
                                        submitLabel,
                                        loading = false,
                                        readonly = false,
                                        onStepChange,
                                        singleStep = false,
                                        metadata,
                                        matiStatus,
                                        reloadMatiStatus,
                                        matiStatusLoading,
}) => {
  const [retry, setRetry] = useState(true);

  const retryInputHandler = useCallback(async () => {
    const newMatiStatus = await reloadMatiStatus();
    setRetry(true);
  }, [reloadMatiStatus]);

  useEffect(() => {
    setRetry(false);
  }, [matiStatus.waiting_for_input_count]);

  if(matiStatus.validated){
    return (
      <div className="PersonalDataMatiVerificationForm">
        <p>Su identidad fue validada correctamente.</p>
        <ButtonCustom
          type="submit"
          className="PersonalDataMatiVerificationForm__submit"
          size="lg"
          block={true}
          variant="primary"
          loading={loading}
          onClick={onSubmit}
        >
          {submitLabel || 'Continuar'}
        </ButtonCustom>
      </div>
    )
  }

  return (
    <div className="PersonalDataMatiVerificationForm">
      {/*<pre>
        {JSON.stringify(matiStatus, null, 2)}
      </pre>*/}
      {matiStatus.waiting_for_auto ? (
        <>
          <Spinner className="VerificationSpinner"/>
          <p>Estamos procesando tus datos, un momento por favor.</p>
        </>
      ) : (
        matiStatus.waiting_for_manual && retry === false ? (
          <>
            {/*<Spinner className="VerificationSpinner"/>*/}
            <p>No pudimos aprobar tus datos automaticamente</p>
            <p>Puede continuar pero tendremos que revisar sus datos manualmente antes de darle acceso a la plataforma.</p>
            {/*matiStatus.can_retry && (
              <>
                <p>O podes <a onClick={retryInputHandler} href='#'>reintentarlo</a></p>
              </>
            )*/}
            <ButtonCustom
              type="submit"
              className="PersonalDataMatiVerificationForm__submit"
              size="lg"
              block={true}
              variant="primary"
              loading={loading}
              onClick={onSubmit}
            >
              {'Continuar con verificacion pendiente'}
            </ButtonCustom>
          </>
        ) : (
          matiStatus.waiting_for_input && retry === false ? (
            <>
              <Spinner className="VerificationSpinner"/>
              <p>
                Estamos esperando que completes el formulario de validacion en la ventana emergente, si lo cerraste podes&nbsp;
                <a onClick={retryInputHandler} href='#'>volver a validar</a>
              </p>
            </>
          ) : (
            matiStatus.can_retry ? (
              <>
                <div className="PersonalDataMatiVerificationForm__mati_container">
                  <mati-button
                    clientid={matiStatus.client_id}
                    flowId={matiStatus.flow_id}
                    metadata={JSON.stringify(metadata)}
                  />
                </div>
                {
                  matiStatus.rejected_attempts > 0 && (
                    <p>Su identidad no pudo ser validada, le recomendamos revisar sus datos personales y asegurarse de estar usando el DNI correcto antes de reintentar.</p>
                  )
                }
              </>
            ) : (
                <div className="PersonalDataMatiVerificationForm">
                  <p>Su identidad no pudo ser validada, le recomendamos revisar sus datos personales y asegurarse de estar usando el DNI correcto.</p>
                  <p>Puede continuar sin verificar pero tendremos que revisar sus datos manualmente antes de darle acceso a la plataforma.</p>
                  <ButtonCustom
                    type="submit"
                    className="PersonalDataMatiVerificationForm__submit"
                    size="lg"
                    block={true}
                    variant="primary"
                    loading={loading}
                    onClick={onSubmit}
                  >
                    {'Continuar sin verificar'}
                  </ButtonCustom>
                </div>
            )
          )
        )
      )}
    </div>
  );
}

PersonalDataMatiVerificationForm.propTypes = {
  onSubmit: PropTypes.func,
  onStepChange: PropTypes.func,
  initialValues: PropTypes.shape({

  }),
  submitLabel: PropTypes.string,
  nextStepLabel: PropTypes.string,
  loading: PropTypes.bool,
  readonly: PropTypes.bool,
  singleStep: PropTypes.bool,
  matiStatus: PropTypes.shape({
    flow_id: PropTypes.string.isRequired,
    client_id: PropTypes.string.isRequired,
    waiting_for_auto: PropTypes.bool.isRequired,
    waiting_for_manual: PropTypes.bool.isRequired,
    waiting_for_input: PropTypes.bool.isRequired,
    waiting_for_input_count: PropTypes.number.isRequired,
    validated: PropTypes.bool.isRequired,
    can_retry: PropTypes.bool.isRequired,
    attempts: PropTypes.number.isRequired,
    rejected_attempts: PropTypes.number.isRequired,
  }).isRequired,
}

export default PersonalDataMatiVerificationForm;