import React, {useEffect, useImperativeHandle} from "react";
import './TopBar.scss';
import PropTypes from 'prop-types';
import {Link, useHistory} from "react-router-dom";
import {connect} from "react-redux";
import route from "../../helpers/route";
import {AiOutlineBell, AiOutlineArrowLeft, AiOutlineMenu} from 'react-icons/ai';
import {toggleSideMenu} from '../../store/actions/menu';

const TopBar = ({
  title,
  backAction,
  user,
  toggleSideMenu,
  unreadNotificationsCount = 0,
  impersonatingData,
  children,
  accessToken,
}) => {
  const history = useHistory();

  const renderBackIcon = () => {
    let onClick = toggleSideMenu;

    if(backAction){
      onClick = typeof backAction === 'function' ? backAction : history.goBack;
    }

    return (
      <a
        href="#"
        className="topbar__icon"
        onClick={e => {e.preventDefault(); onClick();}}
      >
        {backAction ? <AiOutlineArrowLeft/> : <AiOutlineMenu/>}
      </a>
    );
  }

  return (
    <header className="TopBar topbar">
      <div className="topbar__main-content">
        <div className="topbar__left-buttons-container">
          {renderBackIcon()}
        </div>

        <div className="topbar__title-container">
          {title && (
            <h1 className="topbar__title">
              {title}
            </h1>
          )}
        </div>

        <div className="right-buttons-container">
          {/*<Link
            className={`topbar__icon topbar__icon--notifications ${unreadNotificationsCount > 0 && 'topbar__icon--notifications-new'}`}
            to="#"
          >
            <AiOutlineBell/>
          </Link>*/}
        </div>
      </div>

      {children && (
        <div className="topbar__responsive-content">
          {children}
        </div>
      )}

      {impersonatingData && (
        <div className="TopBar__impersonating-bar">
          <div className="TopBar__impersonating-bar-main">
            <div className="">
              Hola {impersonatingData.imposter_name}
            </div>
            <div className="">
              Estás navegando como la agencia {user.business.name}
              <a className="btn TopBar__impersonating-bar-back-btn" href={`${impersonatingData.logout_url}?access_token=${accessToken}`}>
                Volver
              </a>
            </div>
          </div>
        </div>
      )}

    </header>
  )
}

TopBar.propTypes = {
  title: PropTypes.string,
  backAction: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.bool,
  ]),
  unreadNotificationsCount: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf([null]),
  ]),
  user: PropTypes.shape({

  }).isRequired,
  impersonatingData: PropTypes.shape({
    logout_url: PropTypes.string,
    imposter_name: PropTypes.string,
    imposter_identifier: PropTypes.string,
  })
};

const mapStateToProps = state => ({
  sideMenuIsOpen: state.menu.isOpen,
  user: state.auth.user,
  accessToken: state.auth.tokens?.access_token,
});

const mapDispatchToProps = {
  toggleSideMenu,
};

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);