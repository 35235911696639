import {useEffect, useRef} from "react";
import {useFormik} from "formik";
import useAsyncData from "./useAsyncData";

const usePaginator = ({
                        initialFilters = {},
                        keyupTimeoutTime = 500,
                        loader,
                      } = {}) => {
  const submitTimeout = useRef(null);

  const [load, data, loading, error] = useAsyncData( ({setData, setError}, filters) => {
    return loader(filters).then(response => setData(response.data));
  });

  const formik = useFormik({
    initialValues: {
      search: '',
      page: 1,
      per_page: 24,
      ...initialFilters,
    },
    onSubmit(filters){
      clearTimeout(submitTimeout.current);
      submitTimeout.current = null;

      load(filters);
    }
  });

  useEffect(() => {
    formik.submitForm();
  },[formik.values.page, formik.values.per_page]);

  useEffect(() => {
    clearTimeout(submitTimeout.current);
    submitTimeout.current = setTimeout(formik.submitForm, keyupTimeoutTime);

    return () => {
      clearTimeout(submitTimeout.current);
    }
  },[formik.values.search, formik.submitForm]);

  return {
    data,
    loading,
    error,
    formik,
  }
}

export default usePaginator;