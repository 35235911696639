import React, {useState} from 'react';
import EmptyLayout from "../layouts/EmptyLayout/EmptyLayout";
import {Button, Form} from "react-bootstrap";
import './ForgotPasswordPage.scss';
import InputCustom from "../../components/forms/InputCustom";
import {forgotPassword} from "../../store/actions/auth";
import * as Yup from 'yup';
import {connect} from "react-redux";
import {useFormik} from "formik";
import LoginHeaderImage from "../../assets/images/login-header.svg";
import isHubspotValid from '../../helpers/hubspot-email-validation';

const ForgotPasswordPage = ({forgotPassword}) => {
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState(null);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: async(values) => {
      setLoading(true);
      setFeedback(null);

      try{
        await forgotPassword(values.email);
        setFeedback({message: 'Recibirá en su correo indicaciones para recuperar su contraseña.', success: true});
      }catch(e){
        if (e.response.status === 422){
          setFeedback({message: 'Algun campo es incorrecto.', success: false});
        }else if (e.response.status === 400){
          setFeedback({message: 'No existe el correo, ¿Tiene cuenta?', success: false});
        }else{
          setFeedback({message: 'Ocurrio un error al enviar el correo.', success: false});
        }
      }

      setLoading(false);
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email('Email invalido')
        .required('Obligatorio')
        /*.test(
          'is-hubspot-valid',
          'Email invalido.',
          isHubspotValid
        ),*/
    })
  });

  return (
    <EmptyLayout>
      <div className="ForgotPasswordPage auth-content__inner">
        <div className="forgot-password">
          <div className="">
            <img className="forgot-password__header" src={LoginHeaderImage} alt="logo"/>
          </div>
          <h3 className="forgot-password__title auth-title">
            Olvidé mi contraseña
          </h3>
          <Form className="forgot-password__form" onSubmit={formik.handleSubmit}>
            <Form.Group>
              <InputCustom
                title="Email"
                autoComplete="off"
                onChange={formik.handleChange}
                name="email"
                value={formik.values.email}
                id="email"
                type="email"
                disabled={loading || feedback?.success}
                error={formik.touched.email && formik.errors.email}
              />
            </Form.Group>

            <div>
              {feedback && (
                <div className={`auth__feedback ${feedback.success ? 'auth__feedback--success' : 'auth__feedback--error'}`}>
                  {feedback.message}
                </div>
              )}

              <Button
                type="submit"
                className="auth-btn forgot-password__btn"
                size="lg"
                block={true}
                variant="primary"
                disabled={loading || feedback?.success}
              >
                Recuperar contraseña
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </EmptyLayout>
  );
}

ForgotPasswordPage.propTypes = {

};

const mapDispatchToProps = {
  forgotPassword,
};

export default connect(null, mapDispatchToProps)(ForgotPasswordPage);