import React, {useEffect, useMemo} from "react";
import {FormikProvider, useFormik} from "formik";
import * as Yup from "yup";
import {Button, Form} from "react-bootstrap";
import './SocialMediaForm.scss';
import SocialMediaInput from "../SocialMediaInput/SocialMediaInput";
import PropTypes from "prop-types";
import ButtonCustom from "../forms/ButtonCustom";

const SocialMediaForm = ({
                           onSubmit, 
                           initialValues = {}, 
                           submitLabel, 
                           loading = false, 
                           readonly = false, 
                           socialMediaServices,
                           serverError,
}) => {
  const formik = useFormik({
    initialValues: {
      accounts: [],
      ...initialValues,
    },
    onSubmit: values => onSubmit && onSubmit(values),
    validationSchema: () => Yup.object().shape({
      accounts: Yup.array().of(Yup.object().shape({
        service_id: Yup.string().required(),
        url: Yup.string().min(4, 'Muy corto').nullable(),
      }))
    }),
  });

  useEffect(() => {
    formik.setFieldValue('accounts', socialMediaServices.map((service) => {
      return {
        service_id: service.id,
        url: formik.values.accounts.find(a => a.service_id === service.id)?.url || '',
      };
    }))
  }, [socialMediaServices]);

  return (
    <Form className="SocialMediaForm" onSubmit={formik.handleSubmit}>
      <div className="SocialMediaForm__main">
        {formik.values.accounts.map((account, i) => {
          const socialMediaService = socialMediaServices.find(s => s.id === account.service_id);
          return (
            <Form.Group key={account.service_id}>
              <SocialMediaInput
                onChange={formik.handleChange}
                placeholder={socialMediaService?.name}
                name={`accounts.${i}.url`}
                value={formik.values.accounts[i].url}
                id={`accounts.${account.service_id}`}
                error={formik.errors?.accounts?.[i]?.url}
                socialMediaService={socialMediaService}
                disabled={readonly || loading}
              />
            </Form.Group>
          )
        })}
      </div>

      {serverError && (<p className="server-error">{serverError}</p>)}

      {!readonly && (
        <div>
          <ButtonCustom
            type="submit"
            className="SocialMediaForm__submit"
            size="lg"
            block={true}
            variant="primary"
            loading={loading}
          >
            {submitLabel || 'Guardar'}
          </ButtonCustom>
        </div>
      )}
    </Form>
  );
}

SocialMediaForm.propTypes = {
  onSubmit: PropTypes.func,
  initialValues: PropTypes.shape({

  }),
  submitLabel: PropTypes.string,
  loading: PropTypes.bool,
  readonly: PropTypes.bool,
  socialMediaServices: PropTypes.oneOfType([
    () => null,
    PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      main_color: PropTypes.string.isRequired,
      contrast_color: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }))
  ]),
  serverError: PropTypes.string,
}

export default SocialMediaForm;