import React from 'react';
import PropTypes from "prop-types";
import './OnboardingBackBtn.scss';
import {Button} from "react-bootstrap";
import {AiOutlineArrowLeft} from "react-icons/ai";

const OnboardingBackBtn = ({onClick}) => {
  return (
      <Button className="OnboardingBackBtn" onClick={onClick}>
          <AiOutlineArrowLeft/>
      </Button>
  );
}

OnboardingBackBtn.propTypes = {
  onClick: PropTypes.func.isRequired
}

export default OnboardingBackBtn;