import React, {useEffect, useState, useCallback} from 'react';
import './OnboardingPage.scss';
import {connect, useSelector} from "react-redux";
import EmptyLayout from "../layouts/EmptyLayout/EmptyLayout";
import {
  getSocialMediaServices,
  getFiscalConditions,
  getBusinessActivities,
  getBusinessTypes,
  getProvinces
} from '../../store/actions/common';
import {
  getProfile,
  logout
} from '../../store/actions/auth';
import onboardingAPI from '../../api/onboardingAPI';
import OnboardingStepPersonalData from "../../components/OnboardingStepPersonalData/OnboardingStepPersonalData";
import OnboardingStepPersonalDataVerification
  from "../../components/OnboardingStepPersonalDataVerification/OnboardingStepPersonalDataVerification";
import OnboardingStepColleagues from "../../components/OnboardingStepColleagues/OnboardingStepColleagues";
import OnboardingStepBusiness from "../../components/OnboardingStepBusiness/OnboardingStepBusiness";
import OnboardingStepBusinessAdditionalData
  from "../../components/OnboardingStepBusinessAdditionalData/OnboardingStepBusinessAdditionalData";
import OnboardingStepDone from "../../components/OnboardingStepDone/OnboardingStepDone";
import OnboardingStepSocialMedia from "../../components/OnboardingStepSocialMedia/OnboardingStepSocialMedia";
import useAsyncData from "../../hooks/useAsyncData";
import OnboardingStepPhone from "../../components/OnboardingStepPhone/OnboardingStepPhone";
import OnboardingBackBtn from "../../components/OnboardingBackBtn/OnboardingBackBtn";
import AsyncContent from "../../components/AsyncContent/AsyncContent";
import OnboardingStepCheckVerifiedEmail
  from "../../components/OnboardingStepCheckVerifiedEmail/OnboardingStepCheckVerifiedEmail";

const getStepFromOnboardingData = onboardingData => {
  if(onboardingData === null){
    return null;
  }else if(onboardingData.user_data.finished){
    return 'done';
  }else if( ! onboardingData.user_data.phone_verified){
    return 'phone';
  }else if(onboardingData.personal_data === null){
    return 'personal_data';
  }else if(
    onboardingData.personal_data
    && onboardingData.personal_data.confirmation_done === false
    && onboardingData.business === null
  ){
    return 'confirm_identity';
  }else if(onboardingData.business === null){
    return 'business';
  }else if(onboardingData.business && onboardingData.business.cbu === null){
    return 'business_ad';
  }else if(onboardingData.colleagues === null || onboardingData.colleagues.length === 0){
    return 'colleagues';
  }else if( ! onboardingData.user_data.email_verified){
    return 'check_verified_email';
  }else if(onboardingData.business && onboardingData.business.social_media_accounts.length === 0){
    return 'business_social_media';
  }else{
    return null;
  }
}

const OnboardingPage = ({
                          getSocialMediaServices,
                          getFiscalConditions,
                          getBusinessActivities,
                          getBusinessTypes,
                          getProfile,
                          getProvinces,
                          logout
}) => {
  const [step, setStep] = useState(null);

  const onStepDone = useCallback((nextStep, newOnboardingData) => {
    setStep(nextStep);
    loadOnboardingData(newOnboardingData);
  }, []);

  const onPersonalDataDone = useCallback((data) => {
    if(data.personal_data.confirmation_done === false){
      onStepDone('confirm_identity', data);
    }else{
      onStepDone('business', data);
    }
  }, []);

  const [loadOnboardingData, onboardingData, onboardingDataLoading] = useAsyncData(async ({setData}, newOnboardingData) => {
    if(newOnboardingData){
      setData(newOnboardingData);
      return newOnboardingData;
    }
    const response = await onboardingAPI.getOnboarding();
    const onboardingData = response.data.data;
    setData(onboardingData);
    return onboardingData;
  });

  const {businessActivities, businessTypes, fiscalConditions, socialMediaServices} = useSelector(state => state.common.extra);

  const [loadEntities, _, entitiesLoading, entitiesError] = useAsyncData(async ({setError, setLoading}) => {
    return Promise.all([
      loadOnboardingData(),
      getSocialMediaServices(),
      getFiscalConditions(),
      getBusinessActivities(),
      getProvinces(),
      getBusinessTypes(),
    ]).then(([onboardingData]) => {
      setStep(getStepFromOnboardingData(onboardingData));
    }).catch(err => setError('Error al cargar'));
  });

  useEffect(() => {
    loadEntities();
  }, []);

  return (
    <EmptyLayout>
      <div className="OnboardingPage">
        <AsyncContent hasError={!!entitiesError} isLoading={entitiesLoading} onRetryHandler={loadEntities} errorMessage={entitiesError}>
          {step === 'phone' && (
            <OnboardingStepPhone
              onDone={setStep.bind(null, 'personal_data')}
              onboardingData={onboardingData}
            />
          )}
          {step === 'personal_data' && (
            <>
              <OnboardingBackBtn onClick={setStep.bind(null, 'phone')}/>
              <OnboardingStepPersonalData
                onDone={onPersonalDataDone}
                onboardingData={onboardingData}
              />
            </>
          )}
          {step === 'confirm_identity' && (
            <>
              <OnboardingBackBtn onClick={setStep.bind(null, 'personal_data')}/>
              <OnboardingStepPersonalDataVerification
                onDone={onStepDone.bind(null, 'business')}
                onboardingData={onboardingData}
              />
            </>
          )}
          {step === 'business' && (
            <>
              <OnboardingBackBtn onClick={setStep.bind(null, 'personal_data')}/>
              <OnboardingStepBusiness
                onDone={onStepDone.bind(null, 'business_ad')}
                onboardingData={onboardingData}
                businessActivities={businessActivities}
                businessTypes={businessTypes}
                fiscalConditions={fiscalConditions}
              />
            </>
          )}
          {step === 'business_ad' && (
            <>
              <OnboardingBackBtn onClick={setStep.bind(null, 'business')}/>
              <OnboardingStepBusinessAdditionalData
                onDone={onStepDone.bind(null, 'colleagues')}
                onboardingData={onboardingData}
              />
            </>
          )}
          {step === 'colleagues' && (
            <>
              <OnboardingBackBtn onClick={setStep.bind(null, 'business_ad')}/>
              <OnboardingStepColleagues
                onDone={onStepDone.bind(null, 'check_verified_email')}
                onboardingData={onboardingData}
              />
            </>
          )}
          {step === 'check_verified_email' && (
            <>
              <OnboardingBackBtn onClick={setStep.bind(null, 'colleagues')}/>
              <OnboardingStepCheckVerifiedEmail
                onDone={onStepDone.bind(null, 'business_social_media')}
                onboardingData={onboardingData}
                socialMediaServices={socialMediaServices}
              />
            </>
          )}
          {step === 'business_social_media' && (
            <>
              <OnboardingBackBtn onClick={setStep.bind(null, 'colleagues')}/>
              <OnboardingStepSocialMedia
                onDone={onStepDone.bind(null, 'done')}
                onboardingData={onboardingData}
                socialMediaServices={socialMediaServices}
              />
            </>
          )}
          {step === 'done' && (
            <OnboardingStepDone
              onboardingData={onboardingData}
              onDone={(onboardingData) => onboardingData.user_data.ready ? getProfile() : logout()}
            />
          )}
        </AsyncContent>
      </div>
    </EmptyLayout>
  );
}

OnboardingPage.propTypes = {

}

const mapDispatchToProps = {
  getSocialMediaServices,
  getFiscalConditions,
  getBusinessActivities,
  getBusinessTypes,
  getProvinces,
  getProfile,
  logout,
}

export default connect(null, mapDispatchToProps)(OnboardingPage);