import React, {useCallback, useEffect, useMemo, useState, SyntheticEvent, useRef} from "react";
import PropTypes from "prop-types";
import InputCustom from "./InputCustom";
import {Col, InputGroup, Row} from "react-bootstrap";
import './PhoneInputCustom.scss';

const PhoneInputCustom = ({
                       title,
                       id,
                       name,
                       value,
                       error,
                       onValueChange,
                       readOnly,
                       placeholder_prefix = '',
                       placeholder_number = '',
                       maxlength_prefix = false,
                       maxlength_number = false,
                       start_prefix = '',
                       start_number = '',
                       autoComplete,
                       aclaration,
                       disabled = false,
                       className,
                       ...rest
                     }) => {

  const splitValue = useCallback((value) => {
    const pieces = (value || '').split(' ');

    let prefix = pieces[0] || '';
    let number = pieces[1] || '';

    if(start_prefix){
      prefix = prefix.indexOf(start_prefix) === 0 ? prefix.slice(start_prefix.length) : prefix;
    }

    if(start_number){
      number = number.indexOf(start_number) === 0 ? number.slice(start_number.length) : number;
    }

    return {prefix, number}
  }, [start_prefix, start_number]);

  const [calculatedValue, setCalculatedValue] = useState(value);

  useEffect(() => {
    setCalculatedValue(value);
  }, [value]);

  const callOnchange = useCallback((prefix, number) => {
    prefix = prefix.replace(' ', '');
    number = number.replace(' ', '');

    if(prefix === '' && number === ''){
      setCalculatedValue('');
    }else{
      setCalculatedValue(`${start_prefix}${prefix} ${start_number}${number}`);
    }
  }, [start_prefix, start_number, onValueChange]);

  useEffect(() => {
    onValueChange && onValueChange(calculatedValue);
  }, [calculatedValue]);

  const numberValue = useMemo(() => splitValue(calculatedValue).number, [calculatedValue]);
  const prefixValue = useMemo(() => splitValue(calculatedValue).prefix, [calculatedValue]);

  return (
    <div className={`PhoneInputCustom ${className}`}>
      {title && (
        <label htmlFor={id} className="label-inputs">{title}</label>
      )}
      {aclaration && (
        <small className="d-block text-muted mb-2">{aclaration}</small>
      )}
      <Row>
        <Col xs={4}>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text className="PhoneInputCustom__start">{start_prefix}</InputGroup.Text>
            </InputGroup.Prepend>
            <InputCustom name={`${name}_prefix`}
                         id={`${id}_prefix`}
                         autoComplete={autoComplete}
                         error={error ? ' ' : null}
                         type="text"
                         maxLength={maxlength_prefix}
                         className={`${error ? "is-invalid" : ''}`}
                         placeholder={placeholder_prefix}
                         value={prefixValue}
                         onChange={e => callOnchange(e.target.value, numberValue)}
                         disabled={disabled}
                         readOnly={readOnly}
                         pure={true}
                         {...rest}
            />
          </InputGroup>
        </Col>
        <Col xs={8}>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text className="PhoneInputCustom__start">{start_number}</InputGroup.Text>
            </InputGroup.Prepend>
              <InputCustom name={`${name}_number`}
                           id={`${id}_number`}
                           autoComplete={autoComplete}
                           error={error ? ' ' : null}
                           type="text"
                           maxLength={maxlength_number}
                           className={`${error ? "is-invalid" : ''}`}
                           placeholder={placeholder_number}
                           value={numberValue}
                           onChange={e => callOnchange(prefixValue, e.target.value)}
                           disabled={disabled}
                           readOnly={readOnly}
                           pure={true}
                           {...rest}
              />
          </InputGroup>
        </Col>
        <Col xs={12}>
          {error && (
            <div className="text-danger">
              {error}
            </div>
          )}
        </Col>
      </Row>
    </div>
  )
}

PhoneInputCustom.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  error: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  //onChange: PropTypes.func,
  className: PropTypes.string,
}

export default PhoneInputCustom
