import React, {useCallback, useEffect, useState} from 'react';
import './LogoutPage.scss';
import {logout, renewToken} from "../../store/actions/auth";
import {connect} from "react-redux";
import {useHistory} from 'react-router-dom';
import Spinner from "../../components/Spinner/Spinner";
import EmptyLayout from "../layouts/EmptyLayout/EmptyLayout";

const LogoutPage = ({logout, renewToken, isAuth, auth, isUserLoading, impersonateLogoutUrl}) => {
  const history = useHistory();

  const afterLogoutRedirect = useCallback(() => {
    history.replace('/');

    //TODO: If they logout from this menu, we log them out, we dont un-impersonate them, they can do that witrh the grey bar on top.
    //impersonateLogoutUrl ? window.location.replace(impersonateLogoutUrl) : history.replace('/');
  }, []);

  useEffect(() => {
    if(!isUserLoading){
      if(isAuth){
        logout()
          .then(afterLogoutRedirect)
          .catch((err) => {
            console.log('Error al cerrar sesion', {err});
            //TODO: Perform OFFLINE logout from every platform
          });
      }else{
        afterLogoutRedirect();
      }
    }
  }, [isUserLoading])

  return (
    <EmptyLayout>
      <Spinner/>
    </EmptyLayout>
  );
}

const mapStateToProps = state => ({
  auth: state.auth,
  isAuth: state.auth.isAuth,
  isUserLoading: state.auth.isUserLoading,
  impersonateLogoutUrl: state.auth.user?.impersonating?.logout_url,
});

const mapDispatchToProps = {
  logout,
  renewToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(LogoutPage);