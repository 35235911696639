import React from 'react';
import './DefaultLayout.scss';
import OnboardingMenu from '../../../components/OnboardingMenu/OnboardingMenu';
import TopBar from "../../../components/TopBar/TopBar";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {logout} from '../../../store/actions/auth';
import {closeSideMenu, openSideMenu} from "../../../store/actions/menu";
import {useHistory} from "react-router-dom";

const DefaultLayout = ({
                         title,
                         children,
                         backAction,
                         topbarContent,
                         user,
                         isUserLoading,
                         token,
                         sideMenuIsOpen,
                         closeSideMenu,
                         openSideMenu,
                         logout,
}) => {
  const history = useHistory();

  return (
    <div className="DefaultLayout">
      <OnboardingMenu
        menu={user?.menu}
        loading={isUserLoading}
        token={token}
        isOpen={sideMenuIsOpen}
        onOpenMenu={openSideMenu}
        onCloseMenu={closeSideMenu}
        onInternalRedirect={history.push}
      />
      <div className="main-section">
        <TopBar
          unreadNotificationsCount={0}
          backAction={backAction}
          title={title}
          impersonatingData={user?.impersonating}
        >
          {topbarContent}
        </TopBar>
        <div className="content-wrapper">
            {children}
        </div>
      </div>
    </div>
  );
}

DefaultLayout.propTypes = {
  title: PropTypes.string,
  backAction: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.bool,
  ]),
  topBarContent: PropTypes.node,
  user: PropTypes.shape({
    menu: PropTypes.object,
    impersonating: PropTypes.shape({
      logout_url: PropTypes.string,
      imposter_name: PropTypes.string,
      imposter_identifier: PropTypes.string,
    })
  })
};

const mapStateToProps = state => ({
  sideMenuIsOpen: state.menu.sideMenuIsOpen,
  user: state.auth.user,
  token: state.auth.tokens?.access_token,
  isUserLoading: state.auth.isUserLoading,
});

const mapDispatchToProps = {
  closeSideMenu,
  openSideMenu,
};

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);