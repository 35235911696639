import React, {useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {Button, Form} from "react-bootstrap";
import InputCustom from "../forms/InputCustom";
import './PhoneForm.scss';
import PropTypes from 'prop-types';
import ButtonCustom from "../forms/ButtonCustom";
import PhoneInputCustom from "../forms/PhoneInputCustom";

const PhoneForm = ({onSubmit, initialValues = {}, submitLabel, loading = false, readonly = false, serverError}) => {
  const formik = useFormik({
    initialValues: {
      phone: '',
      ...initialValues,
    },
    onSubmit: values => onSubmit && onSubmit(values),
    validationSchema: Yup.object().shape({
      phone: Yup.string()
        .required('Obligatorio')
        .matches(
          "^0(\\d{2,4})\\ 15(\\d{4,8})$",
          "Debe ingresar codigo de area y numero de celular"
        ),
    }),
  });

  return (
    <Form className="PhoneForm" onSubmit={formik.handleSubmit}>
      <Form.Group>
        <PhoneInputCustom
          title="Telefono"
          autoComplete="off"
          maxlength_prefix="4"
          maxlength_number="8"
          start_number="15"
          start_prefix="0"
          placeholder_number="00000000"
          placeholder_prefix="11"
          onValueChange={formik.setFieldValue.bind(null, 'phone')}
          name="phone"
          value={formik.values.phone}
          id="phone"
          disabled={loading || readonly}
          error={formik.touched.phone && formik.errors.phone}
        />
      </Form.Group>

      {serverError && (<p className="server-error">{serverError}</p>)}

      {!readonly && (
        <div>
          <ButtonCustom
            type="submit"
            className="PhoneForm__submit"
            size="lg"
            block={true}
            variant="primary"
            disabled={readonly}
            loading={loading}
          >
            {loading ? 'Enviando...' : (submitLabel || 'Guardar')}
          </ButtonCustom>
        </div>
      )}
    </Form>
  );
}

PhoneForm.propTypes = {
  onSubmit: PropTypes.func,
  initialValues: PropTypes.shape({

  }),
  submitLabel: PropTypes.string,
  loading: PropTypes.bool,
  readonly: PropTypes.bool,
  serverError: PropTypes.string,
}

export default PhoneForm;