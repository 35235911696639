import React, {useCallback, useMemo, useState} from "react";
import './OnboardingMenu.scss';
import Backdrop from "../Backdrop/Backdrop";
import PropTypes from 'prop-types';
import Spinner from "../Spinner/Spinner";
import WhiteLogoImage from '../../assets/images/logo-white.png';
import {Badge, Collapse} from "react-bootstrap";
import {
  FaArrowUp,
  FaBell,
  FaCalculator,
  FaCar, FaCaretDown, FaCaretSquareDown, FaCaretSquareUp, FaCaretUp, FaFacebook, FaFacebookF,
  FaFile,
  FaHome, FaInstagram, FaRecycle,
  FaShoppingCart, FaSignOutAlt,
  FaUser,
  FaUsers,
  FaWhatsapp
} from "react-icons/all";

/**
 *
 * @param isOpen
 * @param onOpenMenu
 * @param onCloseMenu
 * @param logout
 * @param menu
 * @param loading
 * @param token
 * @param platformKey
 * @param onInternalRedirect
 * @returns {JSX.Element}
 * @constructor
 */
const OnboardingMenu = ({
                          isOpen,
                          onOpenMenu,
                          onCloseMenu,
                          menu,
                          loading,
                          token,
                          platformKey = null,
                          onInternalRedirect,
                        }) => {
  return (
    <>
      <aside className={`OnboardingMenu ${isOpen ? 'OnboardingMenu--open' : ''}`}>
        <div className="OnboardingMenu__top">
          <div className="OnboardingMenu__logo-container">
            <img className="OnboardingMenu__logo" src={WhiteLogoImage} alt="Logo"/>
          </div>
          <div className="OnboardingMenu__user-container">
            {loading ? (<Spinner size={20}/>) : (
              <>
                <span>
                  ¡Hola {menu.user_data.fullname}!
                </span>
                <div className="OnboardingMenu__top-divider"/>
                {menu.user_data.contacts.executive && (
                  <>
                    <span>
                      Ejecutivo: {menu.user_data.contacts.executive.name}
                    </span>
                    <br/>
                  </>
                )}
                {menu.user_data.contacts.commercial && (
                  <span>
                    Asesor: {menu.user_data.contacts.commercial.name}
                  </span>
                )}
              </>
            )}
          </div>
        </div>
        <nav className="OnboardingMenu__navbar-container">
          {loading ? (
            <Spinner size={20}/>
          ) : (
            <Navbar
              menu={menu}
              token={token}
              platformKey={platformKey}
              onInternalRedirect={onInternalRedirect}
              onCloseMenu={onCloseMenu}
            />
          )}
        </nav>
      </aside>
      {isOpen && (
        <Backdrop
          onClick={isOpen ? onCloseMenu : onOpenMenu}
          onBack={isOpen ? onCloseMenu : onOpenMenu}
          index={150}
          className="OnboardingMenu__backdrop"
        />
      )}
    </>
  )
}

OnboardingMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  menu: PropTypes.object.isRequired,
  onOpenMenu: PropTypes.func.isRequired,
  onCloseMenu: PropTypes.func.isRequired,
  onInternalRedirect: PropTypes.func.isRequired,
  token: PropTypes.string,
  platformKey: PropTypes.string,
};


/**
 *
 * @param ico
 * @returns {JSX.Element|null}
 * @constructor
 */
const AbstractIcon = ({ico}) => {
  switch(ico){
    case 'home': return <FaHome/>;
    case 'bell': return <FaBell/>;
    case 'recycle': return <FaRecycle/>;
    case 'file': return <FaFile/>;
    case 'cart': return <FaShoppingCart/>;
    case 'car': return <FaCar/>;
    case 'user': return <FaUser/>;
    case 'users': return <FaUsers/>;
    case 'calculator': return <FaCalculator/>;
    case 'whatsapp': return <FaWhatsapp/>;
    case 'instagram': return <FaInstagram/>;
    case 'facebook': return <FaFacebook/>;
    default: return null;
  }
}

/**
 *
 * @param menu
 * @param closeSideMenu
 * @param token
 * @param logout
 * @param onInternalRedirect
 * @param platformKey
 * @param onCloseMenu
 * @returns {JSX.Element}
 * @constructor
 */
const Navbar = ({menu, closeSideMenu, token, onInternalRedirect, platformKey, onCloseMenu}) => {
  const [groupOpen, setGroupOpen] = useState(null);

  const groupToggleHandler = useCallback((groupKey) => {
    setGroupOpen(groupKey === groupOpen ? null : groupKey);
  }, [groupOpen]);

  return (
    <ul className="Navbar">
      {menu.items.map(line => (
        <NavbarLine
          key={line.id}
          closeSideMenu={closeSideMenu}
          line={line}
          groupToggleHandler={groupToggleHandler}
          groupOpen={groupOpen}
          icon={<AbstractIcon ico={line.icon}/>}
          token={token}
          onInternalRedirect={onInternalRedirect}
          platformKey={platformKey}
          onCloseMenu={onCloseMenu}
        />
      ))}

      <li className="Navbar__filler"/>

      <NavbarItem to={menu.logout_url} icon={<FaSignOutAlt/>}>
        Salir
      </NavbarItem>
    </ul>
  )
}

/**
 *
 * @param line
 * @param groupOpen
 * @param groupToggleHandler
 * @param onCloseMenu
 * @param icon
 * @param token
 * @param onInternalRedirect
 * @param platformKey
 * @returns {JSX.Element|null}
 * @constructor
 */
const NavbarLine = ({
                      line,
                      groupOpen,
                      groupToggleHandler,
                      onCloseMenu,
                      icon,
                      token,
                      onInternalRedirect,
                      platformKey
                    }) => {
  let to = '/';

  if(line.platform !== platformKey){
    to = line.url

    if(line.platform){
      to += `?access_token=${token}`; //TODO: Fix, hay que armar el querystring correcto
    }
  }else{
    to = line.path || line.url;
  }

  const onClickHandler = useCallback((e) => {
    onCloseMenu && onCloseMenu();

    if(to.startsWith('/') && onInternalRedirect){
      e.preventDefault();
      onInternalRedirect(to);
    }

  }, [to]);

  switch(line.type){
    case 'item':
      return (
        <NavbarItem
          target={line.external_window ? '_blank' : ''}
          icon={icon}
          onClick={onClickHandler}
          to={to}
          badge={line.badge}
          className={line.highlight ? 'NavbarItem--highlight' : ''}
        >
          {line.name}
        </NavbarItem>
      )

    case 'group': return (
      <NavbarGroup
        groupKey={line.name}
        icon={icon}
        groupOpen={groupOpen}
        onToggle={groupToggleHandler}
        label={line.name}
        badge={line.badge}
      >
        {line.items.map(subLine => (
          <NavbarLine
            key={subLine.id}
            token={token}
            line={subLine}
            groupOpen={groupOpen}
            onToggle={groupToggleHandler}
            platformKey={platformKey}
            onInternalRedirect={onInternalRedirect}
          />
        ))}
      </NavbarGroup>
    )

    case 'divider': return (<NavbarDivider/>)

    default:
      return null;
  }
}

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const NavbarDivider = ({

                       }) => {
  return (
    <li className="NavbarDivider"/>
  );
}

/**
 *
 * @param icon
 * @param to
 * @param children
 * @param onClick
 * @param target
 * @param className
 * @param badge
 * @returns {JSX.Element}
 * @constructor
 */
const NavbarItem = ({
                      icon,
                      to,
                      children,
                      onClick,
                      target,
                      className,
                      badge
                    }) => {
  return (
    <li className={`NavbarItem ${className || ''}`}>
      <a href={to || '#'} onClick={onClick} target={target}>
        <span className="NavbarItem__icon">
          {icon}
        </span>
        {children}
        {badge && (<NavbarItemBadge text={badge}/>)}
      </a>
    </li>
  );
}

/**
 *
 * @param text
 * @returns {JSX.Element}
 * @constructor
 */
const NavbarItemBadge = ({
                           text
                         }) => {
  return (
    <span className="NavbarItem__badge-container">
      <Badge variant="primary">
        {text}
      </Badge>
    </span>
  );
}

/**
 *
 * @param children
 * @param icon
 * @param groupKey
 * @param label
 * @param groupOpen
 * @param onToggle
 * @param badge
 * @returns {JSX.Element}
 * @constructor
 */
const NavbarGroup = ({
                       children,
                       icon,
                       groupKey,
                       label,
                       groupOpen,
                       onToggle,
                       badge
                     }) => {
  const onClick = useCallback(() => {
    onToggle(groupKey);
  }, [onToggle, groupKey]);

  const open = groupKey && groupKey === groupOpen;

  return (
    <li className="NavbarItem NavbarGroup">
      <a href='#' onClick={onClick}>
        <span className="NavbarItem__icon">
          {icon}
        </span>
        {label}
        {badge && (<NavbarItemBadge text={badge}/>)}
        <div className="NavbarGroup__collapse-ico">
          {open ? (<FaCaretUp/>) : (<FaCaretDown/>)}
        </div>
      </a>
      <Collapse in={open}>
        <div>
          <ul className="NavbarGroup__list">
            {children}
          </ul>
        </div>
      </Collapse>
    </li>
  );
}

export default OnboardingMenu;

export {
  Navbar,
  NavbarDivider,
  NavbarGroup,
  NavbarItem,
  NavbarItemBadge,
  NavbarLine,
  OnboardingMenu,
  AbstractIcon,
};