import { applyMiddleware, createStore } from "redux";
import reduxthunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistReducer, persistStore, createTransform } from "redux-persist";
import storage from "redux-persist/lib/storage";
import RootReducer from "./reducers";

const authTransform = createTransform(
  (inboundState, key) => (
    inboundState.remember
      ? {
        tokens: inboundState.tokens,
        isAuth: inboundState.isAuth,
        remember: true,
        user: (inboundState.isAuth && inboundState?.user?.onboarding === null) ? inboundState.user : null
      } : {
        tokens: {},
        isAuth: false,
        remember: false,
        user: null,
      }
  ),
  (outboundState, key) => ({
    ...outboundState,
    tokens: outboundState.tokens,
    user: outboundState.user,
    isAuth: outboundState.isAuth,
    isUserLoading: outboundState.isAuth ? (!outboundState.user) : false,
    remember: outboundState.remember,
  }),
  {whitelist: ['auth']}
);

const persistConfig = {
  key: 'dc-onboarding.auth',
  storage,
  version: 3,
  whitelist: ['auth'],
  transforms: [
    authTransform,
  ]
}

const persistedReducer = persistReducer(persistConfig, RootReducer);
const middlewares = [reduxthunk];

const devTools =
  process.env.NODE_ENV === "production"
    ? applyMiddleware(...middlewares)
    : composeWithDevTools(applyMiddleware(...middlewares));

export const store = createStore(persistedReducer, devTools);
export const persistor = persistStore(store);
