import React, { useState } from "react";
import PropTypes from "prop-types";
import InputCustom from "../forms/InputCustom";
import './SocialMediaInput.scss';

const SocialMediaInput = ({socialMediaService, className = '', ...others}) => {
  return (
    <div className="SocialMediaInput">
        <div className="SocialMediaInput__side_content" style={{backgroundColor: socialMediaService.main_color}}>
          <img
            className="SocialMediaInput__icon"
            src={socialMediaService.icon}
          />
        </div>
        <InputCustom
          style={{backgroundColor: socialMediaService.main_color, color: socialMediaService.contrast_color}}
          className={`${className} SocialMediaInput__input_container`}
          placeholder={socialMediaService.name}
          {...others}
        />
    </div>
  )
}

SocialMediaInput.propTypes = {
  socialMediaService: PropTypes.shape({
    main_color: PropTypes.string.isRequired,
    contrast_color: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
}

export default SocialMediaInput;
