import OnboardingHeader from "../OnboardingHeader/OnboardingHeader";
import React, {useEffect, useState} from "react";
import './OnboardingStepCheckVerifiedEmail.scss';
import PropTypes from 'prop-types';
import onboardingAPI from "../../api/onboardingAPI";
import useAsyncData from "../../hooks/useAsyncData";
import VerifyEmailForm from "../VerifyEmailForm/VerifyEmailForm";

const OnboardingStepCheckVerifiedEmail = ({
                                      onDone,
                                      onboardingData
                                    }) => {
  const [submitHandler, _, loading, error] = useAsyncData(async ({setLoading, setError}, values) => {
    try{
      const response = await onboardingAPI.getOnboarding();

      if(response.data.data.user_data.email_verified){
        onDone && onDone(response.data.data);
      }else{
        setError('Su email no esta validado, verifique el email que recibio');
      }
    }catch(e){
      setError('Ocurrio un error');
    }
  }, [onDone]);

  useEffect(() => {
    //If the user already verified email we skip this messages
    if(onboardingData.user_data.email_verified){
      onDone && onDone(onboardingData);
    }
  }, [onboardingData]);

  return (
    <div className="OnboardingStepCheckVerifiedEmail">
      <OnboardingHeader title="Verificacion de email">
        Debe verificar su email antes de poder continuar
      </OnboardingHeader>
      <VerifyEmailForm
        onSubmit={submitHandler}
        submitLabel="Ya valide mi correo"
        loading={loading}
        serverError={error}
      />
    </div>
  )
}

OnboardingStepCheckVerifiedEmail.propTypes = {
  onDone: PropTypes.func.isRequired,
}

export default OnboardingStepCheckVerifiedEmail;