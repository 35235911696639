import React, {useRef, useEffect, useCallback} from 'react';
import PropTypes from "prop-types";

const FilePicker = ({children, onFileAdded, disabled, ...otherProps}) => {
  const fileInputRef = useRef();

  const addFile = () => {
    fileInputRef.current.click();
  }

  const onFileInputChange = useCallback(e => {
    if(disabled) return;

    const file = e.target.files[0];

    if(file){
      onFileAdded && onFileAdded(file);
    }

    e.target.value = '';
  }, [onFileAdded]);

  useEffect(() => {
    const element = fileInputRef.current;

    element.addEventListener('change', onFileInputChange);

    return () => {
      element.removeEventListener('change', onFileInputChange);
    }
  }, [fileInputRef, onFileAdded, onFileInputChange])

  return (
    <div>
      <input
        style={{display: 'none'}}
        ref={fileInputRef}
        type="file"
        {...otherProps}
      />
      {children({addFile, })}
    </div>
  );
}

FilePicker.propTypes = {
  children: PropTypes.func.isRequired, //Children as a function pattern
  onFileAdded: PropTypes.func.isRequired,
}

export default FilePicker;