import React from 'react';
import './Spinner.scss';
import PropTypes from 'prop-types';

const Spinner = ({size = 120, zIndex = 1, className = ''}) => {
  return (
    <div
      style={{width: `${size}px`, height: `${size}px`, zIndex}}
      className={`Spinner ${className} ${size < 35 ? 'Spinner--small' : ''}`}
    />
  );
};

Spinner.propTypes = {
  size: PropTypes.number,
}

export default Spinner;