import {useDispatch, useSelector} from "react-redux";
import {addAlert, dismissAlert, clearAllAlerts} from "../store/actions/common";

const useAlerts = ({} = {}) => {
  const dispatch = useDispatch();
  const alerts = useSelector(state => state.common.alerts);

  return {
    clearAll: () => {
      dispatch(clearAllAlerts())
    },
    add: (alert) => {
      dispatch(addAlert(alert))
    },
    dismiss: (id) => {
      dispatch(dismissAlert(id))
    },
    items: alerts,
  }
}

export default useAlerts;