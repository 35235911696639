import OnboardingHeader from "../OnboardingHeader/OnboardingHeader";
import React, {useCallback} from "react";
import './OnboardingStepColleagues.scss';
import UserColleaguesForm from "../UserColleaguesForm/UserColleaguesForm";
import PropTypes from 'prop-types';
import onboardingAPI from "../../api/onboardingAPI";
import useAsyncData from "../../hooks/useAsyncData";

const OnboardingStepColleagues = ({onDone, onboardingData}) => {
  const [submitHandler, _, loading, error] = useAsyncData(async ({setLoading, setError}, values) => {
    try{
      const response = await onboardingAPI.saveColleagues(values);
      onDone && onDone(response.data.data);
    }catch(e){
      if(e.response?.status === 422){
        setError('Algun campo es invalido');
      }else{
        setError('Ocurrio un error');
      }
    }
  }, [onDone]);

  return (
    <>
      <OnboardingHeader title="Referidos">
        ¿Tenés algun colega que nos pueda dar referencias tuyas? Nos ayuda mucho a dar el alta rapidamente.
      </OnboardingHeader>
      <UserColleaguesForm
        onSubmit={submitHandler}
        submitLabel="Continuar al próximo paso"
        initialValues={{colleagues: onboardingData.colleagues || []}}
        loading={loading}
        serverError={error}
      />
    </>
  )
}

OnboardingStepColleagues.propTypes = {
  onDone: PropTypes.func.isRequired,
  onboardingData: PropTypes.object.isRequired,
}

export default OnboardingStepColleagues;