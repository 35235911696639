import useAsyncWrap, {CancelledError} from "./useAsyncWrap";
import {useCallback, useEffect, useState} from 'react';

const useAsyncData = (promiseReturningFunction, deps = []) => {
  const asyncWrap = useAsyncWrap();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const loadFunction = useCallback((...args) => {
    setLoading(true);
    setError(null);

    return asyncWrap(
      promiseReturningFunction({setLoading, setError, setData, CancelledError}, ...args)
    ).then(res => {
      setLoading(false);
      return res;
    }).catch(e => {
      if(!(e instanceof CancelledError)){
        setError(e);
        setData(null);
        setLoading(false);
        throw e;
      }
    });
  }, deps);

  return [
    loadFunction,
    data,
    loading,
    error,
  ]
}

export default useAsyncData;
