import React from "react"
import PropTypes from "prop-types"
import {FormControl} from "react-bootstrap";

const SelectCustom = ({ title, id, name, value, error, onChange, placeholder, children, ...rest }) => {
  return (
    <>
      <label htmlFor={id} className="label-inputs">{title}</label>
      <FormControl
        as="select"
        className={`form-control ${error ? 'is-invalid' : ''}`}
        name={name}
        id={id}
        value={value}
        onChange={onChange}
        {...rest}
      >
        {placeholder && <option value={null} disabled selected>{placeholder}</option>}
        {children}
      </FormControl>
      {error && (
        <div className="invalid-feedback">
          {error}
        </div>
      )}
    </>
  )
}

SelectCustom.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
}

export default SelectCustom
