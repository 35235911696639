import onboardingAPI from "../../api/onboardingAPI";
import OnboardingHeader from "../OnboardingHeader/OnboardingHeader";
import React, {useState} from "react";
import './OnboardingStepPhone.scss';
import PropTypes from 'prop-types';
import PhoneForm from "../PhoneForm/PhoneForm";
import PhoneVerificationForm from "../PhoneVerificationForm/PhoneVerificationForm";
import useAsyncData from "../../hooks/useAsyncData";
import OnboardingBackBtn from "../OnboardingBackBtn/OnboardingBackBtn";
import {connect} from "react-redux";
import {logout} from "../../store/actions/auth";

const OnboardingStepPhone = ({onDone, onboardingData, logout}) => {
  const [phone, setPhone] = useState(null);
  const [lastSentAt, setLastSentAt] = useState(null);

  const [submitPhoneHandler, _, savePhoneLoading, savePhoneError] = useAsyncData(async ({setLoading, setError}, values) => {
    try{
      await onboardingAPI.savePhone(values);
      setLastSentAt(new Date());
      setPhone(values.phone);
    }catch(e){
      setError('Ocurrio un error');
    }
  }, [onDone]);

  const [submitVerificationHandler, __, verifyPhoneLoading, verifyPhoneError] = useAsyncData(async ({setLoading, setError}, values) => {
    try{
      const response = await onboardingAPI.verifyPhone(values);
      onDone && onDone(response.data.data);
    }catch(e){
      if(e.response.status === 400){
        setError('El codigo es invalido');
      }else{
        setError('Ocurrio un error');
      }
    }
  }, [onDone]);

  return (
    <div className="OnboardingStepPhone">
      {phone === null ? (
        <>
          <OnboardingBackBtn onClick={logout}/>
          <OnboardingHeader title="Telefono">
            Ingresa tu celular, te enviaremos un codigo para verificarlo.
          </OnboardingHeader>
          <PhoneForm
            initialValues={{phone: onboardingData.user_data.phone}}
            onSubmit={submitPhoneHandler}
            submitLabel="Validar"
            loading={savePhoneLoading}
            serverError={savePhoneError}
          />
        </>
      ) : (
        <>
          <OnboardingBackBtn onClick={setPhone.bind(null, null)}/>
          <OnboardingHeader title="Validacion de teléfono">
            Te enviamos un codigo por SMS. por favor ingresa el codigo a continuacion.
          </OnboardingHeader>
          <PhoneVerificationForm
            initialValues={{phone}}
            onSubmit={submitVerificationHandler}
            onResendRequest={submitPhoneHandler.bind(null, {phone})}
            lastSentAt={lastSentAt}
            submitLabel="Continuar"
            loading={verifyPhoneLoading || savePhoneLoading}
            serverError={verifyPhoneError || savePhoneError}
          />
        </>
      )}
    </div>
  )
}

OnboardingStepPhone.propTypes = {
  onDone: PropTypes.func.isRequired,
  onboardingData: PropTypes.object.isRequired,
}

const mapDispatchToProps = {
  logout,
}

export default connect(null, mapDispatchToProps)(OnboardingStepPhone);