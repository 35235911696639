import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AUTH, SAVE_PROFILE} from "../store/types/auth";
import {getProfile} from "../store/actions/auth";

const useImpersonation = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const dispatch = useDispatch();
  const isAuthLoading = useSelector(state => state.auth.isAuthLoading);

  useEffect(() => {
    if(!isAuthLoading){
      if(urlParams.get('impersonating_token')){
        dispatch({
          type: AUTH,
          payload: {
            tokens: {
              access_token: urlParams.get('impersonating_token'),
              refresh_token: null,
            },
            remember: true,
            isUserLoading: true,
          },
        });

        getProfile()(dispatch);
      }
    }
  }, [isAuthLoading]);
}

export default useImpersonation;