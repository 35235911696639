import OnboardingHeader from "../OnboardingHeader/OnboardingHeader";
import React, {useCallback} from "react";
import './OnboardingStepBusinessAdditionalData.scss';
import PropTypes from 'prop-types';
import BusinessAdditionalDataForm from "../BusinessAdditionalDataForm/BusinessAdditionalDataForm";
import onboardingAPI from "../../api/onboardingAPI";
import useAsyncData from "../../hooks/useAsyncData";

const OnboardingStepBusinessAdditionalData = ({
                                      onDone,
                                      onboardingData,
                                    }) => {

  const [onSubmitHandler, _, loading, error] = useAsyncData(async ({setLoading, setError}, values) => {
    try{
      const response = await onboardingAPI.patchBusiness(onboardingData.business.id, values);
      onDone && onDone(response.data.data);
    }catch(e){
      if(e.response?.status === 422){
        setError('Algun campo es invalido');
      }else{
        setError('Ocurrio un error');
      }
    }
  }, [onDone, onboardingData.business]);

  return (
    <>
      <OnboardingHeader title="Datos adicionales">
        Mientras más datos completes mas rápido operarás con nosotros.
      </OnboardingHeader>
      <BusinessAdditionalDataForm
        onSubmit={onSubmitHandler}
        submitLabel="Continuar al próximo paso"
        initialValues={{cbu: onboardingData.business?.cbu || '', photo_preview: onboardingData.business?.storefront}}
        loading={loading}
        serverError={error}
      />
    </>
  )
}

OnboardingStepBusinessAdditionalData.propTypes = {
  onDone: PropTypes.func.isRequired,
  onboardingData: PropTypes.object.isRequired,
}

export default OnboardingStepBusinessAdditionalData;