import React, {useState} from 'react';
import DefaultLayout from './../layouts/DefaultLayout/DefaultLayout';
import './SettingsPage.scss';
import {connect} from "react-redux";
import {Col, Container, FormGroup, Image, Row} from "react-bootstrap";
import InputCustom from "../../components/forms/InputCustom";
import ChangePasswordModal from '../../components/ChangePasswordModal/ChangePasswordModal';
import Spinner from "../../components/Spinner/Spinner";
import {setAvatarModalIsOpen} from "../../store/actions/menu";

const SettingsPage = ({user, isUserLoading, setAvatarModalIsOpen}) => {
  const [showPasswordModal, setShowPasswordModal] = useState(false);

  const changePasswordHandler = e => {
    e.preventDefault();
    setShowPasswordModal(true);
  }

  const topbarContent = (
    <>
      {isUserLoading ? (<Spinner size={20}/>) : (
        <div className="menu-user menu-user--vertical">
          {/*<div className="menu-user__avatar-container menu-user__avatar-container--big" onClick={() => {setAvatarModalIsOpen(true)}}>
            <Image
              className="menu-user__avatar"
              src={user.avatar}
              alt="Avatar"
            />
          </div>*/}
          <div className="menu-user__data">
            <span className="menu-user__data-name">
              {user.first_name} {user.last_name}
            </span>
            <span className="menu-user__data-email">
              {user.email}
            </span>
          </div>
        </div>
        )
      }
    </>
  );

  return (
    <DefaultLayout title="Configuracion" topbarContent={topbarContent}>
      <div className="SettingsPage">
        <div className="main-container">
          <Container fluid="xl">
            {!isUserLoading && (
              <>
                <div className="page-card">
                  <h2 className="page-card__title">
                    Datos del usuario
                  </h2>
                  <div className="page-card__content">
                    <Row className="mb-4">
                      <Col md={4}>
                        <FormGroup>
                          <InputCustom
                            value={user.email}
                            title="Email"
                            readOnly={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <InputCustom
                            value={user.phone}
                            title="Telefono"
                            readOnly={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <InputCustom
                            type="password"
                            value="------"
                            title="Contraseña"
                            allowShowPassword={false}
                            readOnly={true}
                          />
                          <a href="#" onClickCapture={changePasswordHandler} className="reset-password">
                            Cambiar contraseña
                          </a>
                          {showPasswordModal && (
                            <ChangePasswordModal
                              show={showPasswordModal}
                              handleClose={() => setShowPasswordModal(false)}
                            />
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </div>

                <div className="page-card">
                  <h2 className="page-card__title">
                    Datos personales
                  </h2>
                  <div className="page-card__content">
                    <Row className="mb-4">
                      <Col md={4}>
                        <FormGroup>
                          <InputCustom
                            value={user.personal_data.first_name}
                            title="Nombre"
                            readOnly={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <InputCustom
                            value={user.personal_data.last_name}
                            title="Apellido"
                            readOnly={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <InputCustom
                            value={user.personal_data.dni}
                            title="DNI"
                            readOnly={true}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </div>

                <div className="page-card">
                  <h2 className="page-card__title">
                    Comercio
                  </h2>
                  <div className="page-card__content">
                    <Row className="mb-4">
                      <Col md={4}>
                        <FormGroup>
                          <InputCustom
                            value={user.business.name}
                            title="Nombre"
                            readOnly={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <InputCustom
                            value={user.business.address_full}
                            title="Direccion"
                            readOnly={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <InputCustom
                            value={user.business.phone}
                            title="Telefono"
                            readOnly={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <InputCustom
                            value={user.business.fiscal_condition.name}
                            title="Condicion Fiscal"
                            readOnly={true}
                          />
                        </FormGroup>
                      </Col>
                      {user.business.cuit && (
                        <Col md={4}>
                          <FormGroup>
                            <InputCustom
                              value={user.business.cuit}
                              title="CUIT"
                              readOnly={true}
                            />
                          </FormGroup>
                        </Col>
                      )}
                      <Col md={4}>
                        <FormGroup>
                          <InputCustom
                            value={user.business.activity ? user.business.activity.name : '-'}
                            title="Actividad"
                            readOnly={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <InputCustom
                            value={user.business.type.name}
                            title="Tipo"
                            readOnly={true}
                          />
                        </FormGroup>
                      </Col>
                      {user.business.type_description && (
                        <Col md={4}>
                          <FormGroup>
                            <InputCustom
                              value={user.business.type_description}
                              title="Tipo (Descripcion)"
                              readOnly={true}
                            />
                          </FormGroup>
                        </Col>
                      )}
                      <Col md={4}>
                        <FormGroup>
                          <InputCustom
                            value={user.business.cbu || '-'}
                            title="CBU"
                            readOnly={true}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </div>
              </>
            )}
          </Container>
        </div>
      </div>
    </DefaultLayout>
  );
}

SettingsPage.propTypes = {

};

const mapStateToProps = state => ({
  user: state.auth.user,
  isUserLoading: state.auth.isUserLoading,
});

const mapDispatchToProps = {
  setAvatarModalIsOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);