import React, {useEffect, useMemo} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {Button, Col, Form, Row} from "react-bootstrap";
import './BusinessAdditionalDataForm.scss';
import InputCustom from "../forms/InputCustom";
import ImagePicker from "../ImagePicker/ImagePicker";
import PropTypes from 'prop-types';
import useIsMobile from "../../hooks/useIsMobile";
import useDebouncedCallback from "../../hooks/useDebouncedCallback";
import commonAPI from "../../api/commonAPI";
import onboardingAPI from "../../api/onboardingAPI";
import ButtonCustom from "../forms/ButtonCustom";

const BusinessAdditionalDataForm = ({
                                      onSubmit,
                                      initialValues = {},
                                      submitLabel,
                                      loading = false,
                                      readonly = false,
                                      serverError,
}) => {
  const checkCBU = useDebouncedCallback( cbu => {
    return onboardingAPI.checkCBU({cbu});
  }, 500,[onboardingAPI.checkCBU])

  const formik = useFormik({
    initialValues: {
      photo_preview: null,
      photo: '',
      cbu: '',
      ...initialValues,
    },
    onSubmit: values => {
      const formData = new FormData;

      Object.keys(values).forEach(key => {
        formData.append(key, values[key]);
      });

      onSubmit && onSubmit(formData)
    },
    validationSchema: Yup.object().shape({
      photo: Yup.mixed(),
      cbu: Yup.string()
        .nullable()
        .min(22, '22 Digitos')
        .max(22, '22 Digitos')
        .test(
          'matches-owner',
          'El CBU no parece ser suyo ni de su empresa. Por favor utilice uno que le pertenezca',
          async cbu => !cbu || cbu.length !== 22 || (await checkCBU(cbu)).data.approved
        )
    }),
  });

  const isMobile = useIsMobile();

  useEffect(() => {
    if(formik.values.photo){
      formik.setFieldValue('photo_preview', URL.createObjectURL(formik.values.photo));
    }

    return () => formik.values.photo && formik.setFieldValue('photo_preview', null);
  }, [formik.values.photo]);

  return (
    <Form className="BusinessAdditionalDataForm" onSubmit={formik.handleSubmit}>
      <label>
        Foto de tu negocio
      </label>
      <Row>
        { !isMobile && (<Col sm={3}/>)}
        <Col sm={6}>
          <ImagePicker
            onChange={formik.setFieldValue.bind(this, 'photo')}
            className="BusinessAdditionalDataForm__image_picker"
            label="Adjuntar Foto"
            error={formik.touched.photo && formik.errors.photo}
            preview={formik.values.photo_preview}
            disabled={loading || readonly}
          />
        </Col>
        { isMobile && (
          <Col sm={6}>
            <ImagePicker
              onChange={formik.setFieldValue.bind(this, 'photo')}
              className="BusinessAdditionalDataForm__image_picker"
              label="Sacar Foto"
              capture={true}
              error={formik.touched.photo && formik.errors.photo}
              preview={formik.values.photo_preview}
              disabled={loading || readonly}
            />
          </Col>
        )}
      </Row>

      <Form.Group>
        <InputCustom
          title="CBU"
          onChange={formik.handleChange}
          placeholder="Sin guiones intermedios, solo numeros"
          name="cbu"
          maxLength={22}
          value={formik.values.cbu}
          id="cbu"
          error={formik.touched.cbu && formik.errors.cbu}
          disabled={loading || readonly}
        />
      </Form.Group>

      {serverError && (<p className="server-error">{serverError}</p>)}

      {!readonly && (
        <div>
          <ButtonCustom
            type="submit"
            className="BusinessAdditionalDataForm__submit"
            size="lg"
            block={true}
            variant="primary"
            loading={loading}
          >
            {submitLabel || 'Guardar'}
          </ButtonCustom>
        </div>
      )}
    </Form>
  );
}

BusinessAdditionalDataForm.propTypes ={
  onSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  submitLabel: PropTypes.string,
  loading: PropTypes.bool,
  readonly: PropTypes.bool,
  serverError: PropTypes.string,
}

export default BusinessAdditionalDataForm;