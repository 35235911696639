import {AUTH, LOGOUT, SAVE_PROFILE, SET_INTENDED_URL} from "../types/auth"

const initialState = {
    isUserLoading: false,
    user: null,
    isAuth: false,
    tokens: {},
    remember: false,
    intendedUrl: null,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case AUTH:
            return {
                ...state,
                isUserLoading: action.payload.isUserLoading !== undefined ? action.payload.isUserLoading : state.isUserLoading,
                tokens: action.payload.tokens,
                remember: action.payload.remember,
                isAuth: true,
            }

        case LOGOUT:
            return {
                ...initialState,
                isUserLoading: false,
                intendedUrl: null,
            }

        case SAVE_PROFILE:
            return {
                ...state,
                user: action.payload,
                isUserLoading: false,
            }

        case SET_INTENDED_URL:
            return {
                ...state,
                intendedUrl: action.payload,
            }

        default: {
            return state;
        }
    }
}
