import {useCallback, useEffect} from "react";
import {debounce} from "debounce";

const useDebouncedCallback = (callback, wait = 500, deps = []) => {
  const debouncedFunction = useCallback(debounce(callback, wait), deps);

  useEffect(() => {
    return () => debouncedFunction.flush();
  }, [debouncedFunction]);

  return debouncedFunction;
}

export default useDebouncedCallback;