import React, {useCallback} from "react";
import PropTypes from 'prop-types';
import './PaginationPager.scss';

const PageItem = ({onClickHandler, children, disabled, active}) => {
  return (
    <li className={`PaginationPager__page page-item ${disabled && 'disabled'} ${active && 'active'}`}>
      <a onClick={onClickHandler} className="page-link" href="#">
        {children}
      </a>
    </li>
  )
}

PageItem.propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  onClickHandler: PropTypes.func.isRequired,
}

const PaginationPager = ({pagination}) => {
  const onPageChangeHandler = useCallback(page => {
    if(pagination){
      pagination.formik.setFieldValue('page', page);
    }
  }, [pagination]);

  if(!pagination.data){
    return null;
  }

  if(pagination.loading){
    return (
      <div>...</div>
    );
  }

  const meta = pagination.data.meta;

  const pagesToShow = [
    ...new Set([
      1,
      1 + 1,
      //...
      meta.current_page - 1,
      meta.current_page,
      meta.current_page + 1,
      //...
      meta.last_page - 1,
      meta.last_page,
    ].filter(page => (page >= 1 && page <= meta.last_page)))
  ];

  const thisPageCount = meta.total ? (meta.to - meta.from + 1) : 0;

  return (
    <nav className="PaginationPager">
      <p className="PaginationPager__row-count">
        Mostrando {thisPageCount} de {meta.total} registros {/* (Filtrado de un total de X registros) */}
      </p>
      <ul className="PaginationPager__group pagination">
        <PageItem
          disabled={meta.current_page <= 1}
          onClickHandler={() => onPageChangeHandler(meta.current_page - 1)}
        >
          <span>&laquo;</span>
        </PageItem>
        {pagesToShow.map(page => (
          <PageItem
            key={page}
            active={page === meta.current_page}
            onClickHandler={() => onPageChangeHandler(page)}
          >
            <span>{page}</span>
          </PageItem>
        ))}
        <PageItem
          disabled={meta.current_page >= meta.last_page}
          onClickHandler={() => onPageChangeHandler(meta.current_page + 1)}
        >
          <span>&raquo;</span>
        </PageItem>
      </ul>
    </nav>
  )
}

PaginationPager.propTypes = {
  pagination: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    data: PropTypes.shape({
      meta: PropTypes.shape({
        per_page: PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
        ]).isRequired,
        from: PropTypes.number,
        last_page: PropTypes.number.isRequired,
        current_page: PropTypes.number.isRequired,
        to: PropTypes.number,
        total: PropTypes.number.isRequired,
      })
    })
  }).isRequired
}

export default PaginationPager;