import HomePage from "../pages/HomePage/HomePage"
import LoginPage from "../pages/LoginPage/LoginPage";
import SettingsPage from "../pages/SettingsPage/SettingsPage";
import RecoverPasswordPage from "../pages/RecoverPasswordPage/RecoverPasswordPage";
import ForgotPasswordPage from "../pages/ForgotPasswordPage/ForgotPasswordPage";
import RegisterPage from "../pages/RegisterPage/RegisterPage";
import OnboardingPage from "../pages/OnboardingPage/OnboardingPage";
import UsersIndexPage from "../pages/UsersIndexPage/UsersIndexPage";
import UsersFormPage from "../pages/UsersFormPage/UsersFormPage";
import UsersCreatePage from "../pages/UsersCreatePage/UsersCreatePage";
import LogoutPage from "../pages/LogoutPage/LogoutPage";
import VerifyEmailPage from "../pages/VerifyEmailPage/VerifyEmailPage";
import NewsPage from "../pages/NewsPage/NewsPage";

export default {
  index: { path: "/", component: HomePage, auth: 'ready' },
  settings: { path: "/configuracion", component: SettingsPage, auth: 'ready' },
  users: { path: "/usuarios", component: UsersIndexPage, auth: 'ready' },
  users_create: { path: "/usuarios/nuevo", component: UsersCreatePage, auth: 'ready' },
  users_edit: { path: "/usuario/:id", component: UsersFormPage, auth: 'ready' },
  news: { path: "/novedades", component: NewsPage, auth: 'ready' },

  onboarding: { path: "/onboarding", component: OnboardingPage, auth: 'not_ready' },

  login: { path: "/ingresar", component: LoginPage, auth: false },
  logout: { path: "/logout", component: LogoutPage, auth: null},
  register: { path: "/registro", component: RegisterPage, auth: false },
  forgot_password: { path: "/olvide_mi_contrasena", component: ForgotPasswordPage, auth: false },
  recover_password: { path: "/nueva_contrasena/:token", component: RecoverPasswordPage, auth: false },
  verifyEmail: { path: "/verificar_correo", component: VerifyEmailPage, auth: null},
}