import {Link} from "react-router-dom";
import React, {useState} from "react";
import PropTypes from 'prop-types';
import useAsyncData from "../../hooks/useAsyncData";
import usersAPI from "../../api/usersAPI";
import Spinner from "../Spinner/Spinner";

const UserRow = ({user, to}) => {
  const [passwordEmailResend, passwordEmailResent, passwordEmailLoading, passwordEmailError] = useAsyncData( ({setData, setError}, e) => {
    e.preventDefault();
    e.stopPropagation();

    return usersAPI.resendPasswordEmail(user.id)
      .then(() => setData(true))
      .catch((e) => setError(e));
  }, [user.id]);

  return (
    <Link className="UserRow" to={to}>
      <div className="UserRow__inner">
        <p className="UserRow__line UserRow__line--title">
          {user.personal_data.first_name} {user.personal_data.last_name}
          <span> - </span>
          DNI {user.personal_data.dni}
        </p>
        <p className="UserRow__line">
          {user.email}
        </p>
        <p className="UserRow__line">
          {user.enabled ? 'Habilitado' : 'Deshabilitado'}
        </p>
        {!user.has_password && (
          <>
            <p className="UserRow__line">
              Aun no creo su contraseña&nbsp;
            </p>
            <p className="UserRow__line">
              {passwordEmailLoading ? (
                <Spinner size={10}/>
              ) : (
                passwordEmailResent
                  ? 'Correo reenviado'
                  : (
                    <a href="#" onClick={passwordEmailResend}>
                      {passwordEmailError ? 'Error al reenviar, reintentar' : 'Reenviar correo'}
                    </a>
                  )
              )}
            </p>
          </>
        )}
      </div>
    </Link>
  );
}

UserRow.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    created_at: PropTypes.string.isRequired,
    has_password: PropTypes.bool.isRequired,
    enabled: PropTypes.bool.isRequired,
  }).isRequired,
  to: PropTypes.string,
};

export default UserRow;