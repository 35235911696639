import React, {useEffect, useState, useMemo} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {Button, Form} from "react-bootstrap";
import './PhoneVerificationForm.scss';
import PropTypes from 'prop-types';
import CodeCustom from "../forms/CodeCustom";
import ButtonCustom from "../forms/ButtonCustom";

const calcTimeLeft = (lastSentAt) => lastSentAt && (lastSentAt - new Date + 3 * 60 * 1000) / 1000; //3 Minutos antes de reintentar

const PhoneVerificationForm = ({
                                 onSubmit,
                                 initialValues = {},
                                 submitLabel,
                                 loading = false,
                                 readonly = false,
                                 onResendRequest,
                                 lastSentAt,
                                 serverError,
}) => {
  const formik = useFormik({
    initialValues: {
      code: '',
      ...initialValues,
    },
    onSubmit: values => onSubmit && onSubmit(values),
    validationSchema: Yup.object().shape({
      code: Yup.string().required('Obligatorio').matches(/^[0-9]{4}$/, '4 Digitos'),
    }),
  });

  const [leftForResend, setLeftForResend] = useState(calcTimeLeft(lastSentAt));

  useEffect(() => {
    const interval = setInterval(() => {
      setLeftForResend(lastSentAt && calcTimeLeft(lastSentAt) > 0 ? calcTimeLeft(lastSentAt) : null);
    }, 1000);

    return () => clearInterval(interval);
  }, [lastSentAt]);

  useEffect(() => {
    formik.validateForm(formik.values);
  }, []);

  useEffect(() => {
    if(formik.isValid){
      formik.submitForm();
    }
  }, [formik.isValid]);

  const leftForResendFormatted = useMemo(() => {
    return leftForResend && Math.floor(leftForResend / 60) + ':' + ((leftForResend % 60)).toFixed(0);
  }, [leftForResend]);

  return (
    <Form className="PhoneVerificationForm" onSubmit={formik.handleSubmit}>
      <Form.Group className="PhoneVerificationForm__code_group">
        <CodeCustom
          fields={4}
          autoComplete="off"
          placeholder=""
          onChange={formik.setFieldValue.bind(null, 'code')}
          name="code"
          value={formik.values.code}
          id="code"
          disabled={loading || readonly}
          error={formik.touched.code && formik.errors.code}
        />
      </Form.Group>

      {onResendRequest && (
        <div className="PhoneVerificationForm__resend_box">
          {leftForResend ? (
              <p>Podras reenviar el SMS en {leftForResendFormatted}</p>
            ) : (
              <a className="PhoneVerificationForm__resend_box_link" onClick={onResendRequest}>
                Reenviar SMS
              </a>
            )
          }
        </div>
      )}

      {serverError && (<p className="server-error">{serverError}</p>)}

      {!readonly && (
        <div>
          <ButtonCustom
            type="submit"
            className="PhoneForm__submit"
            size="lg"
            block={true}
            variant="primary"
            loading={loading}
            disabled={!formik.isValid}
          >
            {loading ? 'Verificando...' : (submitLabel || 'Verificar')}
          </ButtonCustom>
        </div>
      )}
    </Form>
  );
}

PhoneVerificationForm.propTypes = {
  onSubmit: PropTypes.func,
  initialValues: PropTypes.shape({

  }),
  submitLabel: PropTypes.string,
  loading: PropTypes.bool,
  readonly: PropTypes.bool,
  onResendRequest: PropTypes.func.isRequired,
  lastSentAt: PropTypes.oneOfType([
    PropTypes.object,
    () => null
  ]),
  serverError: PropTypes.string,
}

export default PhoneVerificationForm;