import React from 'react';
import DefaultLayout from './../layouts/DefaultLayout/DefaultLayout';
import './UsersIndexPage.scss';
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import usersAPI from "../../api/usersAPI";
import Pagination from "../../components/Pagination/Pagination";
import PaginationPager from "../../components/PaginationPager/PaginationPager";
import usePaginator from "../../hooks/usePaginator";
import route from "../../helpers/route";
import UserRow from "../../components/UserRow/UserRow";
import {AiOutlineFilter} from "react-icons/all";
import {Link} from "react-router-dom";

const UsersIndexPage = () => {
  const usersPagination = usePaginator({
    loader: (filters) => usersAPI.index(filters),
    //loader: usersAPI.index,
  });

  return (
    <DefaultLayout title="Usuarios">
      <div className="UsersIndexPage">
        <div className="main-container">
          <Container fluid="xl" className="container--xs-full">
            <Row>
              <Col xs={12} className="text-right mb-2">
                <Link
                  className="btn btn-primary"
                  to={route('users_create')}
                >
                  Nuevo usuario
                </Link>
              </Col>
            </Row>
            <div className="page-card">
              {/*<h2 className="page-card__title">
                Usuarios
              </h2>*/}
              <div className="page-card__content">
                <Form onSubmit={usersPagination.formik.handleSubmit} className="filter-form">
                  <Row className="no-gutters">
                    <Col xs={10} sm={11} md={4}>
                      <input
                        type="text"
                        id="search"
                        name="search"
                        autoComplete="off"
                        onChange={usersPagination.formik.handleChange}
                        value={usersPagination.formik.values.search}
                        placeholder="Ingresá Nombre, Apellido, Email o Dni"
                        className="filter-form__input form-control"
                        disabled={0 && usersPagination.loading}
                      />
                    </Col>
                    <Col xs={2} sm={1} md={2}>
                      <Button type="submit" variant="default" disabled={usersPagination.loading}>
                        <AiOutlineFilter/>
                      </Button>
                    </Col>
                  </Row>
                </Form>
                <div className="">
                  <Row>
                    <Pagination pagination={usersPagination}>
                      {users => users.length ? users.map(user => (
                        <Col key={user.id} xs={12} sm={6} lg={4}>
                          <UserRow
                            user={user}
                            to={route({name: 'users_edit', params: {id: user.id}})}
                          />
                        </Col>
                      )) : (
                        <Col xs={12}><h5 className="no-records">No hay usuarios</h5></Col>
                      )}
                    </Pagination>
                  </Row>
                  <Row>
                    <PaginationPager pagination={usersPagination}/>
                  </Row>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </DefaultLayout>
  );
}

UsersIndexPage.propTypes = {

};

export default UsersIndexPage;