import {useDispatch, useSelector} from "react-redux";
import route from "../../helpers/route";
import React from 'react';
import {Redirect, Route, useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import {SET_INTENDED_URL} from "../../store/types/auth";

const AuthRoute = ({redirect, component: Component, auth = false, ...restProps}) => {
  const authState = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const location = useLocation();

  const render = (props) => {
    if (! [null, undefined].includes(auth)){
      if(auth === false){
        if(authState.isAuth !== false){
          return <Redirect to={authState.intendedUrl || redirect || route('index')}/>
        }
      }else{
        if(authState.isAuth !== true){
          dispatch({type: SET_INTENDED_URL, payload: location.pathname + location.search});
          return <Redirect to={redirect || route('login')}/>
        }else if( ! authState.isUserLoading){
          if(auth === 'onboarding'){
            if(authState.user.finished_at){
              return <Redirect to={redirect || route('index')}/>
            }
          }else if(auth === 'ready'){
            if(! authState.user.ready){
              return <Redirect to={redirect || route('onboarding')}/>
            }
          }else if(auth === 'not_ready'){
            if(authState.user.ready){
              return <Redirect to={redirect || route('index')}/>
            }
          }
        }
      }
    }

    return <Component {...props} />
  }

  return <Route {...restProps} render={render}/>
}

AuthRoute.propTypes = {
  redirect: PropTypes.string,
  auth: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
}

export default AuthRoute;