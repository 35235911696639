import { combineReducers } from "redux";
import auth from "./auth";
import menu from "./menu";
import common from './common';

export default combineReducers({
    auth,
    menu,
    common,
})
