import React, {useRef, useEffect, useCallback} from 'react';
import PropTypes from "prop-types";
import {
  AiOutlinePaperClip,
  AiOutlineCamera,
} from "react-icons/ai";
import './ImagePicker.scss';
import FilePicker from "../FilePicker/FilePicker";
import {Button} from "react-bootstrap";

const ImagePicker = ({
                       children,
                       label,
                       capture,
                       icon: CustomIcon,
                       disabled,
                       className,
                       onChange,
                       accept,
                       error,
                       preview,
                       ...others
}) => {
  const DefaultIcon = capture ? AiOutlineCamera : AiOutlinePaperClip;

  return (
    <FilePicker disabled={disabled} accept={accept || 'image/*'} capture={capture || null} {...others} onFileAdded={onChange}>
      {({addFile}) => (
        <a
          onClick={addFile}
          className={`ImagePicker ${className || ''}`}
          style={preview ? {backgroundImage: `url(${preview})`} : {}}
        >
          <h5 className="ImagePicker__label">
            {label}
          </h5>
          {children && (
            <div className="ImagePicker__content">
              {children}
            </div>
          )}
          <Button disabled={disabled} variant="primary" className="ImagePicker__icon">
            {CustomIcon ? (<CustomIcon/>) : (<DefaultIcon/>)}
          </Button>
          { error && (<p className="ImagePicker__error">{error}</p>)}
        </a>
      )}
    </FilePicker>
  );
}

ImagePicker.propTypes = {
  label: PropTypes.string,
  capture: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  icon: PropTypes.node,
  disabled: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  className: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  accept: PropTypes.string,
  error: PropTypes.string,
}

export default ImagePicker;