import HttpService from "./HttpService"

const httpService = HttpService.getInstance();

export default {
  getOnboarding: async () => await httpService.authCall("GET", '/api/onboarding/current'),
  savePersonalData: async (data) => await httpService.authCall("POST", '/api/onboarding/personal_data', {data}),
  savePhone: async (data) => await httpService.authCall("POST", '/api/onboarding/phone', {data}),
  verifyPhone: async (data) => await httpService.authCall("POST", '/api/onboarding/phone/verify', {data}),
  verifyRenaperIdentity: async (data) => await httpService.authCall("POST", '/api/onboarding/verify_renaper_identity', {data}),
  verifyVerazIdentity: async (data) => await httpService.authCall("POST", '/api/onboarding/verify_veraz_identity', {data}),
  getVerazQuestions: async () => await httpService.authCall("GET", '/api/onboarding/veraz_questions'),
  getMatiStatus: async () => await httpService.authCall("GET", '/api/onboarding/mati_status'),
  getBusinessName: async (params) => await httpService.authCall("GET", '/api/onboarding/business_name', {params}),
  checkCBU: async (params) => await httpService.authCall("GET", '/api/onboarding/check_cbu', {params}),
  saveBusiness: async (data) => await httpService.authCall("POST", '/api/onboarding/business', {data}),
  patchBusiness: async (id, data) => await httpService.authCall("POST", `/api/onboarding/business/${id}`, {data}),
  saveColleagues: async (data) => await httpService.authCall("POST", '/api/onboarding/colleagues', {data}),
  finish: async () => await httpService.authCall('POST', '/api/onboarding/finish'),
}
