import React, {useCallback, useEffect, useRef, useState} from "react";
import PropTypes from 'prop-types';
import {Button, Form, Modal} from "react-bootstrap";
import InputCustom from "../forms/InputCustom";
import './LocationSearchModal.scss';

const getTypeFromPlace = (place, type) => {
  return place.address_components.find(comp => comp.types.includes(type)) || null;
}

const LocationSearchModal = ({show, onHide, onLocationSelected, className, initialPoint}) => {
  const [location, setLocation] = useState(null);
  const mapElementRef = useRef();
  const autocompleteElementRef = useRef();

  const selectHandler = useCallback(location => {
    onLocationSelected && onLocationSelected(location);
    onHide();
  }, [onLocationSelected]);

  const mapInited = useRef(false);

  useEffect(() => {
    if(!show){
      setLocation(null);
      mapInited.current = false;
    }
  }, [show]);

  useEffect(() => {
    if(mapElementRef.current && autocompleteElementRef.current && show && mapInited.current === false){
      mapInited.current = true;

      const map = new window.google.maps.Map(mapElementRef.current, {
        center: {lat: -40, lng: -65},
        zoom: 4,
        styles: {},
        disableDefaultUI: true
      });

      const autocomplete = new window.google.maps.places.Autocomplete(
        autocompleteElementRef.current,
        {
          componentRestrictions: {country: 'ar'},
          //types: ['geocode'],
        }
      );

      autocomplete.bindTo('bounds', map);

      const infowindow = new window.google.maps.InfoWindow();
      const marker = new window.google.maps.Marker({map, anchorPoint: new window.google.maps.Point(0, -29)});

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        marker.setVisible(false);

        if(!place.geometry){
          //setLocation(null);
        }else{
          if (place.geometry.viewport) {
            map.fitBounds(place.geometry.viewport);
          } else {
            map.setCenter(place.geometry.location);
            map.setZoom(17);
          }

          marker.setIcon(({
            url: place.icon,
            size: new window.google.maps.Size(71, 71),
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(17, 34),
            scaledSize: new window.google.maps.Size(35, 35)
          }));

          marker.setPosition(place.geometry.location);
          marker.setVisible(true);
          infowindow.setContent(place.adr_address);
          infowindow.open(map, marker);

          setLocation({
            street: getTypeFromPlace(place, 'route'),
            number: getTypeFromPlace(place, 'street_number'),
            locality: getTypeFromPlace(place, 'locality'),
            province: getTypeFromPlace(place, 'administrative_area_level_1'),
            country: getTypeFromPlace(place, 'country'),
            postal_code: getTypeFromPlace(place, 'postal_code'),
            geo: place.geometry?.location || null
          });
        }
      });
    }
  }, [mapElementRef.current, autocompleteElementRef.current, show]);

  return (
    <Modal
      className={`LocationSearchModal ${className || ''}`}
      show={show}
      onHide={onHide}
    >
        <Modal.Header closeButton={true}>
          <Modal.Title>Buscar ubicacion</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <InputCustom
            ref={autocompleteElementRef}
          />
          <div className="LocationSearchModal__map" ref={mapElementRef}/>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="outline-primary"
            onClick={onHide}
          >
            Cerrar
          </Button>
          <Button
            variant="primary"
            onClick={() => selectHandler(location)}
            disabled={location === null}
          >
            Seleccionar ubicacion
          </Button>
        </Modal.Footer>
    </Modal>
  );
}

LocationSearchModal.propTypes = {
  className: PropTypes.string,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onLocationSelected: PropTypes.func.isRequired,
}

export default LocationSearchModal;