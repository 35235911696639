import React from "react";
import PropTypes from 'prop-types';
import {Button} from "react-bootstrap";
import './AsyncContent.scss';
import Spinner from './../Spinner/Spinner';

const DefaultLoadingComponent = () => {
  return (
    <div className="async-content__loader async-content__loader--default">
      <Spinner size={120}/>
    </div>
  )
}

const DefaultErrorComponent = ({errorMessage, onRetryHandler}) => {
  return (
    <div className="async-content__error async-content__error--default">
      <div>
        <p>{errorMessage || 'Ha ocurrido un error'}</p>
        {onRetryHandler && (
          <Button onClick={onRetryHandler} variant="primary">
            Reintentar
          </Button>
        )}
      </div>
    </div>
  )
}

const AsyncContent = ({
                        children,
                        isLoading,
                        hasError,
                        errorMessage,
                        error,
                        loading,
                        content,
                        onRetryHandler
}) => {
  if(isLoading){
    return loading || (<DefaultLoadingComponent/>);
  }

  if(hasError){
    return error || (<DefaultErrorComponent errorMessage={errorMessage} onRetryHandler={onRetryHandler}/>);
  }

  return content || children || null;
}

AsyncContent.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  error: PropTypes.node,
  loading: PropTypes.node,
  content: PropTypes.node,
  onRetryHandler: PropTypes.func,
}

export default AsyncContent;