import React, {useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {Button, Form} from "react-bootstrap";
import InputCustom from "../forms/InputCustom";
import './PersonalDataForm.scss';
import SelectCustom from "../forms/SelectCustom";
import PropTypes from 'prop-types';
import ButtonCustom from "../forms/ButtonCustom";

const PersonalDataForm = ({
                            onSubmit,
                            initialValues = {},
                            submitLabel,
                            loading = false,
                            readonly = false,
                            serverError
                          }) => {

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      dni: '',
      gender: null,
      ...initialValues,
    },
    onSubmit: values => onSubmit && onSubmit(values),
    validationSchema: Yup.object().shape({
      first_name: Yup.string().required('Obligatorio'),
      last_name: Yup.string().required('Obligatorio'),
      dni: Yup.string()
        .length(8, '8 Digitos')
        .matches(
          "^(\\d{8})$",
          "Debe ingresar un DNI valido"
        ).required('Obligatorio'),
      gender: Yup.mixed().oneOf(['M', 'F', 'Invalido']).required('Obligatorio'),
    }),
  });

  return (
    <Form className="PersonalDataForm" onSubmit={formik.handleSubmit}>
      <Form.Group>
        <InputCustom
          title="Nombres"
          autoComplete="off"
          placeholder="Si tenes mas de un nombre ingresalo"
          onChange={formik.handleChange}
          name="first_name"
          value={formik.values.first_name}
          id="first_name"
          type="text"
          disabled={loading || readonly}
          error={formik.touched.first_name && formik.errors.first_name}
        />
      </Form.Group>

      <Form.Group>
        <InputCustom
          title="Apellidos"
          autoComplete="off"
          placeholder="Ingresa tu apellido completo"
          onChange={formik.handleChange}
          name="last_name"
          value={formik.values.last_name}
          id="last_name"
          type="text"
          disabled={loading || readonly}
          error={formik.touched.last_name && formik.errors.last_name}
        />
      </Form.Group>

      <Form.Group>
        <InputCustom
          title="DNI"
          autoComplete="off"
          placeholder="Ingresa tu DNI sin guiones, solo números"
          onChange={formik.handleChange}
          name="dni"
          maxLength={8}
          value={formik.values.dni}
          id="dni"
          type="text"
          disabled={loading || readonly}
          error={formik.touched.dni && formik.errors.dni}
        />
      </Form.Group>

      <Form.Group>
        <SelectCustom
          title="Sexo"
          onChange={formik.handleChange}
          placeholder="Seleccionar como figura en tu DNI"
          name="gender"
          value={formik.values.gender}
          id="gender"
          type="text"
          disabled={loading || readonly}
          error={formik.touched.gender && formik.errors.gender}
        >
          <option value="M">
            Masculino
          </option>
          <option value="F">
            Femenino
          </option>
        </SelectCustom>
      </Form.Group>

      {serverError && (<p className="server-error">{serverError}</p>)}

      {!readonly && (
        <div>
          <ButtonCustom
            type="submit"
            className="PersonalDataForm__submit"
            size="lg"
            block={true}
            variant="primary"
            loading={loading}
          >
            {submitLabel || 'Guardar'}
          </ButtonCustom>
        </div>
      )}
    </Form>
  );
}

PersonalDataForm.propTypes = {
  onSubmit: PropTypes.func,
  initialValues: PropTypes.shape({

  }),
  submitLabel: PropTypes.string,
  loading: PropTypes.bool,
  readonly: PropTypes.bool,
  serverError: PropTypes.string,
}

export default PersonalDataForm;