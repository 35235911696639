import onboardingAPI from "../../api/onboardingAPI";
import PersonalDataForm from "../PersonalDataForm/PersonalDataForm";
import OnboardingHeader from "../OnboardingHeader/OnboardingHeader";
import React, {useCallback} from "react";
import './OnboardingStepPersonalData.scss';
import PropTypes from 'prop-types';
import useAsyncData from "../../hooks/useAsyncData";

const OnboardingStepPersonalData = ({onDone, onboardingData}) => {

  const [submitHandler, _, loading, error] = useAsyncData(async ({setLoading, setError}, values) => {
    try{
      const response = await onboardingAPI.savePersonalData(values);
      onDone && onDone(response.data.data);
    }catch(e){
      if(e.response?.status === 422){
        setError('Los datos son invalidos');
      }else{
        setError('Ocurrio un error');
      }
    }
  }, [onDone]);

  return (
    <div className="OnboardingStepPersonalData">
      <OnboardingHeader title="Datos personales">
        Toda la informacion que nos brindes es privada, no sera compartida con otra entidad.
      </OnboardingHeader>
      <PersonalDataForm
        onSubmit={submitHandler}
        submitLabel="Continuar al próximo paso"
        initialValues={onboardingData.personal_data}
        loading={loading}
        serverError={error}
      />
    </div>
  )
}

OnboardingStepPersonalData.propTypes = {
  onDone: PropTypes.func.isRequired,
  onboardingData: PropTypes.object.isRequired,
}

export default OnboardingStepPersonalData;