import {useMemo, useState, useRef, useEffect, useCallback} from 'react';
import * as Yup from "yup";

const getSingleStepData = () => ({
  currentStep: null,
  currentStepIndex: null,
  isLastStep: true,
  isFirstStep: true,
  nextStep: null,
  previousStep: null,
  singleStepMode: true,
});

/**
 *
 * This stepper hook allows to divide a formik form into arbitrary steps (small sections)
 * It supports single step mode, that unifies all the steps (for example you can have multi step in mobile, but single step in desktop)
 *
 *
 * @param {string|int|null} initialStep
 * @param {function|undefined} onStepChange
 *  callback for when the step changes
 *
 * @param {function|undefined} onFinish
 *  callback for when the stepper reaches the end and is submitted
 *
 * @param {array|int} steps
 *  array of steps in the form or an integer to form a secuencial steps array
 *    ex: [1, 2, 3] or [1, 2, 3, 4, 5]. An integer like 3 or 5 would produce the same arrays as the first examples
 *    you can also use named steps ['personal_data', 'other_step', 'final_step']
 *
 * @param {boolean} singleStepMode
 *  whether or not the stepper is in single step mode. in single step mode, every step is active at the same time
 *  allowing you to conditionally make the form a single/multi step form.
 *
 * @returns {object} {
 *
 *  currentStep, //Current step key (as specified inside the stepsArray values), not the index. null for singleStepMode
 *  currentStepIndex, //Current step index (0 for the first one, 1 for the second one, this can be different than the keys ('first_step', 'final_step'))
 *  isLastStep, //Whether or not the current step is the last (Always true for single step mode)
 *  isFirstStep, //Whether or not the current step is the first (Always true for single step mode)
 *  stepsArray, //Array of steps (as specified as prop if array, or converted to array if int provided)
 *  nextStep, //Next step key or null if no previous step,
 *  previousStep, // Previous step key or null if no previous step,
 *  singleStepMode, //Whether the stepper is in single step mode or not (same as prop),
 *
 *  isStepActive, //Function to check if a specific step is active (always true for singleStep mode) ex: isStepActive('personal_data') or isStepActive(1)
 *  goForward, //Function to go forward one step (Skipping validation && onStepChange) //TODO: Should this call onStepChange?
 *  goBackward, //Function to go backward one step (Skipping validation && onStepChange) //TODO: Should this call onStepChange?
 *  goToStep, //Function to go to specific step (Skipping validation && onStepChange) //TODO: Should this call onStepChange?
 *  submitHandler, //Function that should be provided to formik as a submitHandler, it will change step or submit depending if the current step is the last or no
 *
 *  validationSchema, //Higher order function that returns a function that handles the validation schema for formik,
 *      instead of providing a Yup schema to formik, you can provide the result of calling this function with a different
 *      schema for every step as in the next example
 *        useFormik({
 *            validationSchema: stepper.validationSchema({ //This returns another function that will return the schema specified for each step
 *              'step_1': Yup.object().shape({...}),
 *              'step_2': Yup.object().shape({...}),
 *
 *              //You can use numbers 1, 2, 3 if you have numeric keys or specified an integer in "steps" prop
 *              1: Yup.object().shape({...}),
 *              2: Yup.object().shape({...}),
 *            })
 *        })
 * }
 */
const useStepper = ({
                      initialStep = 1,
                      onStepChange,
                      onFinish,
                      steps,
                      singleStepMode = false,
}) => {
  const [currentStep, setCurrentStep] = useState(singleStepMode ? null : initialStep);

  const stepsArray = useMemo(() => {
    if(Array.isArray(steps)){
      return steps;
    }

    //If steps argument is a number, we build an array analogue to range(steps) in php, [1, 2, 3, 4, up to "steps" value]
    return Array.from(new Array(steps), (x, i) => i + 1);
  }, [steps]);

  useEffect(() => {
    setCurrentStep(singleStepMode ? null : initialStep);
  }, [singleStepMode]);

  /* Building helper data and keeping it updated in a ref */
  const stepData = useMemo(() => {
    if(singleStepMode){
      return getSingleStepData();
    }

    const currentStepIndex = stepsArray.indexOf(currentStep);
    const isFirstStep = currentStepIndex === 0;
    const isLastStep = currentStepIndex === stepsArray.length - 1;

    return {
      currentStep,
      currentStepIndex,
      isLastStep,
      isFirstStep,
      stepsArray,
      nextStep: isLastStep ? null : stepsArray[currentStepIndex + 1],
      previousStep: isFirstStep ? null : stepsArray[currentStepIndex - 1],
      singleStepMode: false,
    }
  }, [stepsArray, currentStep, singleStepMode]);

  const stepDataRef = useRef(stepData);

  useEffect(() => {
    stepDataRef.current = stepData;
  });
  /* Building helper data and keeping it updated in a ref */

  useEffect(() => {
    if(stepsArray.indexOf(currentStep) === -1){
      setCurrentStep(singleStepMode ? null : stepsArray.slice().shift());
    }
  }, [stepsArray]);

  /* Functions that cant change */
  const validationSchemaFuncBuilder = useCallback(validationSchema => {
    return componentProps => {
      if(stepDataRef.current.singleStepMode){
        //If single step mode, we merge all steps validation rules
        return Yup.object().shape(Object.values(validationSchema).reduce((obj, rules) => ({...obj, ...rules}), {}));
      }else{
        //If not single step mode we only validate that step rules
        return Yup.object().shape(validationSchema[stepDataRef.current.currentStep] || {});
      }
    }
  }, []);

  const submitHandler = useCallback((values, formik) => {
    if(stepDataRef.current.isLastStep){
      onFinish && onFinish(values);
    }else{
      formik && formik.setTouched({}, false);
      setCurrentStep(stepDataRef.current.nextStep);
      onStepChange && onStepChange(stepDataRef.current.nextStep);
    }
  }, []);
  /* Functions that cant change */

  const goForward = useCallback(() => {
    if(stepDataRef.current.nextStep === null){
      throw new Error('No more steps');
    }
    setCurrentStep(stepDataRef.current.nextStep);
  }, []);

  const goBackward = useCallback(() => {
    if(stepDataRef.current.previousStep === null){
      throw new Error('No previous steps');
    }
    setCurrentStep(stepDataRef.current.previousStep);
  }, []);

  const isStepActive = stepToCheck => {
    return singleStepMode ? true : stepToCheck === currentStep;
  };

  return {
    ...stepData,
    currentStep,
    validationSchema: validationSchemaFuncBuilder,
    submitHandler,
    goForward,
    goBackward,
    singleStepMode,
    isStepActive,
    goToStep: setCurrentStep,
  }
}

export default useStepper;
