import React, {useEffect} from 'react';
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";
import routes from './config/routes';
import {
    BrowserRouter as Router,
    ConnectedRouter,
    Route,
    Switch,
} from 'react-router-dom';
import {connect, useSelector} from "react-redux";
import {getProfile, logout, renewToken} from "./store/actions/auth";
import AuthRoute from "./components/AuthRoute/AuthRoute";
import Spinner from "./components/Spinner/Spinner";
import usePlatformRedirection from "./hooks/usePlatformRedirection";
import useImpersonation from "./hooks/useImpersonation";
import AlertContainer from "./components/AlertContainer/AlertContainer";
import useAlerts from "./hooks/useAlerts";

function App({loadProfile, isAuth, logout, isUserLoading, renewToken,}) {
  usePlatformRedirection();
  useImpersonation();

  const alerts = useAlerts();

  /* Cargar perfil */
  useEffect(() => {
    if(isAuth){
      loadProfile()
        .catch(() => {
          alerts.add({title: 'Error', message: 'Ocurrio un error al cargar su perfil'});
          logout();
        });
    }
  }, []);
  /* Cargar perfil */

  if(isUserLoading){
    return (
      <div className="global-loading">
        <Spinner size={100}/>
      </div>
    );
  }

  return (
    <>
      <Router>
        <Switch>
          {Object.keys(routes).map((index) => {
            const body = routes[index];
            return (
              <AuthRoute
                key={index}
                exact
                path={body.path}
                component={body.component}
                auth={body.auth}
              />
            )
          })}
          <Route path="*">
            <NotFoundPage />
          </Route>
        </Switch>
      </Router>
      <AlertContainer/>
    </>
  );
}

const mapStateToProps = state => ({
  isUserLoading: state.auth.isUserLoading,
  isAuth: state.auth.isAuth,
  user: state.auth.user,
});

const mapDispatchToProps = {
  loadProfile: getProfile,
  logout: logout,
  renewToken: renewToken,
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
