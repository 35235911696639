import React, {forwardRef, useState} from "react";
import PropTypes from "prop-types";
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai';
import { FormControl, InputGroup } from "react-bootstrap";

const InputCustom = forwardRef(({
                       title,
                       id,
                       name,
                       value,
                       error,
                       onChange,
                       type = "text",
                       readOnly,
                       placeholder,
                       autoComplete,
                       aclaration,
                       disabled = false,
                       className,
                       allowShowPassword = true,
                       pure = false,
                       ...rest
                     }, ref) => {
  const [showingPassword, setShowingPassword] = useState(false)

  const renderButton = () => {
    if(type === 'password' && allowShowPassword){
      return (
        <InputGroup>
          <FormControl
            ref={ref}
            name={name}
            id={id}
            className={`${error && "is-invalid"}`}
            type={showingPassword ? "text" : "password"}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            disabled={disabled}
            readOnly={readOnly}
            {...rest}
          />
          <InputGroup.Append>
            <InputGroup.Text
              onClick={() => setShowingPassword(old => !old)}
              className="toggle-password"
            >
              {showingPassword ? <AiOutlineEye/> : <AiOutlineEyeInvisible/>}
            </InputGroup.Text>
          </InputGroup.Append>
          <div className="invalid-feedback">
            {error}
          </div>
        </InputGroup>
      )
    }else{
      return (
        <>
          <FormControl
            ref={ref}
            name={name}
            id={id}
            autoComplete={autoComplete}
            type={type}
            className={`${error && "is-invalid"}`}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            disabled={disabled}
            readOnly={readOnly}
            {...rest}
          />
          <div className="invalid-feedback">
            {error}
          </div>
        </>
      )
    }
  }

  if(pure){
    return renderButton();
  }

  return (
    <div className={className}>
      {title && (
        <label htmlFor={id} className="label-inputs">{title}</label>
      )}
      {aclaration && (
        <small className="d-block text-muted mb-2">{aclaration}</small>
      )}
      {renderButton()}
    </div>
  )
});

InputCustom.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  error: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  onChange: PropTypes.func,
  className: PropTypes.string,
}

export default InputCustom
